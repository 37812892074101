import React, { Component } from "react";
import moment from "moment/moment";
import { presence, timeDiff } from "helpers";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { MdDirectionsCar } from "react-icons/md";
import {
  Card,
  CardIcon,
  CardHeader,
} from "material-dashboard-react/components";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { fetchTilesData } from "api/aboutVehicleEntries";
import { Row, Skeleton } from "antd";
import { handleResponse } from "components/notifications/handleResponse";
import RecentEntryGraph from "../../views/Home/Sections/recentEntryGraph.jsx";

const VehicleDetails = ({ vehicleDetails }) => {
  const {
    number_plate,
    owner_name,
    last_visited,
    color,
    icon,
    avg_duration,
  } = vehicleDetails;

  return (
    <Card
      style={{
        flex: 1,
        marginRight: 20,
        paddingBottom: 20,
      }}
    >
      <CardHeader color={color} stats icon>
        <CardIcon color={color}>{icon}</CardIcon>
        <table className="vehicle-details">
          <tr>
            <td className="dash">Number Plate</td>
            <td>{presence(number_plate)}</td>
          </tr>
          <tr>
            <td>Owner Name</td>
            <td>{presence(owner_name && owner_name.toUpperCase())}</td>
          </tr>
          <tr>
            <td>Last Visited</td>
            <td>{timeDiff(moment(), last_visited)}</td>
          </tr>
          <tr>
            <td>Average Duration</td>
            <td>
              {avg_duration && moment.duration(avg_duration * 1000).humanize()}
            </td>
          </tr>
        </table>
      </CardHeader>
    </Card>
  );
};

class AboutVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      skeleten: true,
      vehicles: [],
      vehicleTiles: {},
    };
  }

  componentDidMount = () => {
    this.fetchCallVehicle();
  };
  fetchCallVehicle = () => {
    fetchTilesData(this.props.number_plate)
      .then(({ data: { number_plate, owner, last_visited, avg_duration } }) => {
        const { name: owner_name } = owner || {};
        this.setState({
          vehicleTiles: {
            number_plate,
            owner_name,
            last_visited,
            avg_duration,
        },
          skeleten: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const { vehicleTiles, skeleten, vehicles } = this.state;
    const { number_plate } = vehicleTiles;

    const vehicleInfo = {
      ...vehicleTiles,
      color: "primary",
      icon: <MdDirectionsCar />,
    };

    if (skeleten) {
      return (
        <React.Fragment>
          <Row>
            <Skeleton
              style={{ display: "flex" }}
              active={true}
              title={false}
              paragraph={{
                rows: 5,
              }}
            />
          </Row>
        </React.Fragment>
      );
    }

    if (number_plate) {
      return (
        <React.Fragment>
          <Row style={{ display: "flex" }}>
            <VehicleDetails vehicleDetails={vehicleInfo} />
            <RecentEntryGraph
              alertTypes={vehicles}
              graphHeight="250"
              number_plate={number_plate}
            />
          </Row>
        </React.Fragment>
      );
    }

    return <></>;
  }
}

AboutVehicle.propTypes = {
  number_plate: PropTypes.string.isRequired,
};

export default withStyles(dashboardStyle)(AboutVehicle);
