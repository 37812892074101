import React from "react";
import { Paper } from "@material-ui/core";
import TableBody from "./tableBody";
import TableHeader from "components/paginationTables/tableHeader";

const PaginationTable = (props) => {
  const {
    tabColor,
    title,
    subTitle,
    columns,
    data,
    onRowClick,
    dataValueUpload,
    dataValue,
    uploadAction,
    OnClickUpload,
  } = props;
  const headerprops = {
    tabColor,
    title,
    subTitle,
    OnClickUpload,
    dataValueUpload,
    dataValue,
    uploadAction,
  };
  const bodyprops = {
    columns,
    data,
    onRowClick,
  };
  return (
    <Paper className="paperClass" style={{ maxHeight: "100vh" }}>
      <TableHeader {...headerprops} />
      <TableBody {...bodyprops} />
    </Paper>
  );
};
export default PaginationTable;
