import React from "react";
import { presence } from "helpers";
import { MdOpenInNew } from "react-icons/md";
import { Button } from "antd";

const Status = ({ value }) => {
  const colors = {
    active: "#4caf50",
    inactive: "#ff5252",
  };

  return (
    <span
      style={{
        color: "#fff",
        backgroundColor: colors[value] || "#9c27b0",
        width: 12,
        height: 12,
        display: "inline-block",
        borderRadius: 50,
      }}
    />
  );
};

export const columns = (editRegisteredCard) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      render: (name) => `${presence(name)}`,
    },
    {
      title: "Fin Number",
      dataIndex: "fin_number",
      align: "center",
      render: (fin_number) => `${presence(fin_number)}`,
    },
    {
      title: "Employer",
      dataIndex: "employer",
      align: "center",
      render: (employer) => `${presence(employer)}`,
    },
    // {
    //   title: 'Sub Visitor',
    //   dataIndex: 'sub_visitor',
    //   align: 'center',
    //   render: (sv) => `${presence(titleize(sv))}`,
    // },
    {
      title: "Phone",
      dataIndex: "phone",
      align: "center",
      render: (phone) => `${presence(phone)}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => <Status value={status} />,
    },
    {
      title: "Manage",
      align: "center",
      render: (row) => {
        return (
          <React.Fragment>
            <div>
              <Button
                onClick={() => {
                  editRegisteredCard(row);
                }}
                size="medium"
                style={{
                  margin: "0px 10px 5px 10px",
                }}
              >
                Edit
              </Button>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      title: "Link",
      align: "center",
      render: (row) => {
        return (
          <React.Fragment>
            <div
              style={{
                marginTop: "1px",
              }}
            >
              <a href={`${row.link}`} target="_blank" rel="noopener noreferrer">
                <MdOpenInNew
                  style={{
                    fontSize: "20px",
                    color: "#4290ff",
                    marginLeft: "15px",
                  }}
                />
              </a>
            </div>
          </React.Fragment>
        );
      },
    },
  ];
};
