import React from "react";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { Row, Col } from "antd";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  InputBase,
  styled,
} from "@material-ui/core";
import { vehicleData } from "config";

export default function Form(props) {
  const { handleChange } = props;
  const { number_plate, vehicle_type } = props;

  return (
    <React.Fragment>
      <Row spacing={3} style={{ padding: 10 }}>
        <Col xs={24} sm={12} style={{ padding: "0px 10px", marginTop: "12px" }}>
          <SimpleTextInput
            labelText="Number Plate"
            name="number_plate"
            required
            value={number_plate}
            onChange={handleChange}
          />
        </Col>
        <Col
          xs={24}
          sm={12}
          style={{
            padding: "0px 10px",
            textAlign: "center",
            marginTop: "0px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="age-simple">Vehicle Type</InputLabel>
            <Select
              style={{ width: "100%", height: "84%" }}
              value={vehicle_type}
              onChange={handleChange}
              name="vehicle_type"
              input={<BootstrapInput />}
            >
              {vehicleData.map((val, i) => {
                return (
                  <MenuItem key={i} value={val.value}>
                    {val.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 4,
      border: "2px solid  purple",
    },
  },
}));
