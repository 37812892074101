import React, { useState, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import {
  Card,
  CardIcon,
  CardHeader,
  CardBody,
  CardFooter,
} from "material-dashboard-react/components";
import { Col } from "antd";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import Button from "./Button";
import ConfirmBox from "./ConfirmBox";
import IconButton from "@material-ui/core/IconButton";
import { MdDelete, MdAdd, MdEdit } from "react-icons/md";

const useStyles = makeStyles({
  card: {
    margin: 0,
  },
  title: {
    textAlign: "left",
    color: "#00acc1",
    fontSize: 18,
    marginTop: 20,
    textTransform: "capitalize",
  },
  deleteIcon: {
    color: "red",
    border: "1px solid red",
    borderRadius: 6,
    transition: "background 0.5s",
    "&:hover": {
      backgroundColor: "red",
      color: "white !important",
    },
  },
  forgot: {
    color: "blue",
    border: "1px solid blue",
    borderRadius: 6,
    transition: "background 0.5s",
    "&:hover": {
      backgroundColor: "red",
      color: "red !important",
    },
  },
});

const customStyles = {
  cardHeader: {
    padding: "0px 30px 10px",
    borderBottom: "1px solid lightgray",
    margin: 0,
  },
  cardFooter: {
    padding: "20px 30px",
    margin: 0,
    borderTop: "1px solid lightgray",
  },
  success: {
    color: "#5bb25f",
  },
  info: {
    color: "#11b8c9",
  },
  default: {
    color: "#000",
  },
  primary: {
    color: "rgb(158, 55, 180)",
  },
  warning: {
    color: "#fb9107",
  },
  danger: {
    color: "#e73e3a",
  },
};

const DialogForm = (titleText) => (WrappedComponent) => {
  const HOC = (props) => {
    titleText = titleText || props.titleText;
    const childRef = React.useRef();
    const classes = useStyles();
    const { onCancel, deleteDialogBoxContent, tabColor } = props;
    const [open, setOpen] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [logOutUser, setlogOutUser] = useState(false);
    const [title, setTitle] = useState(`Create ${titleText}`);
    const [method, setMethod] = useState("Create");
    const [dialogAction, setdialogAction] = useState();
    const [submitted, setSubmitted] = useState(false);
    const submitClick = () => {
      setSubmitted((prev) => !prev);
    };

    useEffect(() => {
      if (props.data) {
        setTitle(`Update ${titleText}`);
        setMethod("Update");
        setShowDelete(props.showDelete);
        setlogOutUser(props.logOutUser);
        return;
      }
      if (props.upload) {
        setTitle(`Upload ${titleText}`);
        setMethod("Upload");
        return;
      }
      if (props.schedule_update) {
        setTitle(`Update ${titleText}`);
        setMethod("Reschedule");
        return;
      }
      setTitle(`Create ${titleText}`);
      setMethod("Create");
      setShowDelete(false);
      setlogOutUser(false);
    }, [props.data, props.showDelete, props.logOutUser, props.upload]);
    return (
      <React.Fragment>
        <Dialog
          open={props.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              overflow: "visible",
              minWidth: props.paperSize === "medium" ? 600 : "50%",
            },
          }}
        >
          <Col xs={24} sm={24} md={24}>
            <Card className={classes.card}>
              <CardHeader stats icon style={customStyles.cardHeader}>
                {method !== "Upload" && (
                  <CardIcon
                    color={tabColor}
                    style={{ padding: 10, borderRadius: 5 }}
                  >
                    {method === "create" ? (
                      <MdAdd style={{ fontSize: "25px" }} />
                    ) : (
                      <MdEdit style={{ fontSize: "25px" }} />
                    )}
                  </CardIcon>
                )}
                {method !== "Upload" && (
                  <p className={classes.title} style={customStyles[tabColor]}>
                    {title}
                  </p>
                )}
                {method === "Upload" && (
                  <p className={classes.title} style={customStyles[tabColor]}>
                    {title}
                  </p>
                )}
              </CardHeader>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  childRef.current.onSubmit();
                }}
              >
                <CardBody>
                  <WrappedComponent
                    ref={childRef}
                    {...{ ...props, submitClick }}
                  />
                </CardBody>
                <CardFooter style={customStyles.cardFooter}>
                  {showDelete && (
                    <IconButton
                      className={classes.deleteIcon}
                      onClick={() => {
                        setOpen(true);
                        setdialogAction("confirmDelete");
                      }}
                      aria-label="Delete"
                    >
                      <MdDelete />
                    </IconButton>
                  )}

                  {logOutUser && (
                    <Button
                      className={classes.forgot}
                      text={"Logout User"}
                      onClick={() => {
                        setOpen(true);
                        setdialogAction("logoutUser");
                      }}
                    ></Button>
                  )}
                  <div style={{ flexGrow: 1 }} />
                  <Button onClick={onCancel} text={"Cancel"} />
                  <Button
                    color="success"
                    type="submit"
                    text={method}
                    autoFocus
                    disabled={submitted}
                  />
                </CardFooter>
              </form>
            </Card>
          </Col>
        </Dialog>
        {logOutUser && (
          <ConfirmBox
            headerContent="Confirm Logout"
            open={open}
            confirmBtnText="LOGOUT"
            content="Are you sure want to logout the user?"
            onDelete={() => {
              childRef.current[dialogAction]();
              setOpen(false);
            }}
            onCancel={() => setOpen(false)}
            fontSize="24px"
          />
        )}
        {!logOutUser && (
          <ConfirmBox
            headerContent="Confirm Deletion"
            open={open}
            content={deleteDialogBoxContent}
            onDelete={() => {
              childRef.current[dialogAction]();
              setOpen(false);
            }}
            onCancel={() => setOpen(false)}
          />
        )}
      </React.Fragment>
    );
  };
  HOC.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    showDelete: PropTypes.bool,
    logOutUser: PropTypes.bool,
    deleteDialogBoxContent: PropTypes.string,
    tabColor: PropTypes.oneOf([
      "info",
      "success",
      "primary",
      "danger",
      "warning",
      "default",
    ]),
    paperSize: PropTypes.oneOf(["medium", "large"]),
  };

  HOC.defaultProps = {
    open: false,
    paperSize: "medium",
    tabColor: "default",
  };
  return HOC;
};

export default DialogForm;
