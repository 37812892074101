import React, { Component } from "react";
import moment from "moment";
import { columns } from "data/employeeEntry";
import { Col } from "antd";
import AppBar from "components/AppBar";
import { withRouter } from "react-router-dom";
import PaginationTable from "components/paginationTables/withRefreshDownload";
import { createAccess, debounce } from "helpers";
import {
  fetchEmployeesEntry,
  aggregation,
  triggerReportDownload,
} from "api/employeeEntry";
import { handleResponse } from "components/notifications/handleResponse";
import { success } from "components/notifications/handleResponse";
// import ShowResidentDetails from "./ShowResidentDetails";
class EmployeeEntries extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.globalData = [];
    this.state = {
      data: [],
      total_entries: 0,
      page: 1,
      skeleton: true,
      filter: {},
      filterData: {},
      ...this.initialFilterState(),
    };
  }
  parseAggregation = (data) => {
    return data?.map((key) => ({
      label: key.key,
      value: key.key,
    }));
  };

  initialFilterState = () => {
    let name = [];
    let phone = [];
    let device_name = [];
    let created_by = [];

    return {
      name,
      phone,
      device_name,
      created_by,
    };
  };
  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.fetchTableData();
    this.timer = setTimeout(this.autoRefresh, 10000);
  };
  residentfilterChange = (obj) => {
    this.setState({
      name: obj.name,
      phone: obj.phone,
      device_name: obj.device_name,
      created_by: obj.created_by,
    });
    this.requestParams.name = obj.name;
    this.requestParams.phone = obj.phone;
    this.requestParams.device_name = obj.device_name;
    this.requestParams.created_by = obj.created_by;
    this.debounceSearch();
  };
  initialRequestParams = () => {
    return {
      fromTime: moment.utc(moment().startOf("day")).format(),
      toTime: moment.utc(moment().endOf("day")).format(),
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount = () => {
    this.fetchTableData();
    let scrollY = 0;
    this.autoRefresh();
    setTimeout(function() {
      window.scrollTo({ top: scrollY });
    }, 1000);
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  fetchTableData = () => {
    fetchEmployeesEntry(this.requestParams)
      .then(({ data, total_entries }) => {
        this.globalData = data;
        this.setState({
          total_entries: total_entries,
          skeleton: false,
        });
        this.filterData();
      })
      .catch((error) => {
        console.log(error);
        handleResponse(error);
      });
  };

  onDateChange = (from_time, to_time) => {
    this.requestParams.fromTime = from_time;
    this.requestParams.toTime = to_time;
    this.requestParams.page = 1;
    this.fetchTableData();
  };

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.filterData();
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.fetchTableData();
  };

  createAction = () => {
    return createAccess("resident_entries");
  };

  filterData = () => {
    if (!this.requestParams.search) {
      this.setState({ data: this.globalData });
      return;
    }
    this.setState({
      data: this.globalData.filter((obj) =>
        obj.resident?.name
          .toLowerCase()
          ?.includes(this.requestParams.search.toLowerCase())
      ),
    });
  };

  getAggregation = async () => {
    let params = {
      fromTime: this.requestParams.fromTime,
      toTime: this.requestParams.toTime,
    };
    await aggregation(params)
      .then(({ data }) => {
        const name = data["resident.name"];
        const phone = data["resident.phone"];
        const device_name = data["device_name"];
        const created_by = data["created_by"];
        let names = this.parseAggregation(name["resident.name"]["buckets"]);
        let phones = this.parseAggregation(phone["resident.phone"]["buckets"]);
        let device_names = this.parseAggregation(
          device_name["device_name"]["buckets"]
        );
        let created_bys = this.parseAggregation(
          created_by["created_by"]["buckets"]
        );
        this.setState({
          names,
          phones,
          device_names,
          created_bys,
        });
      })
      .catch((error) => {
        console.log(error);
        handleResponse(error);
      });
  };
  generateReport = () => {
    triggerReportDownload(this.requestParams, this.state.total_entries)
      .then((data) => {
        success(data.message);
      })
      .catch((error) => {
        console.log(error);
        handleResponse(error);
      });
  };

  render() {
    const {
      data,
      names,
      name,
      phone,
      phones,
      created_by,
      created_bys,
      device_name,
      device_names,
    } = this.state;

    const {
      onChange,
      onDateChange,
      fetchTableData,
      generateReport,
      residentfilterChange,
      getAggregation,
    } = this;

    const tableProps = {
      tabColor: "success",
      title: "Employee Entry Logs",
      subTitle: "Employee Entry from vizitin records can be monitored here",
      columns: columns,
      data: data,
      pagination: this.pagination(),
      triggerRefresh: fetchTableData,
      handleTableChange: this.handleTableChange,
      generateReport: generateReport,
    };

    const appBarProps = {
      fromTime: moment(this.requestParams.fromTime),
      toTime: moment(this.requestParams.toTime),
      search: this.requestParams.search,
      dateFilter: true,
      onChange,
      onDateChange,
      getAggregation,
      residentfilterChange: residentfilterChange,
      residentFilter: true,
      residentEntryFilterobj: {
        names,
        name,
        phone,
        phones,
        created_by,
        created_bys,
        device_name,
        device_names,
      },
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        {/* <ShowResidentDetails
          row={row}
          visible={visible}
          routeHandler={routeHandler}
          handleCancel={handleCancel}
        /> */}
        <Col>
          <PaginationTable {...tableProps} />
        </Col>
      </React.Fragment>
    );
  }
}

export default withRouter(EmployeeEntries);
