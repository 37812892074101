import React, { Component } from "react";
import { Tabs } from "antd";
import { GiCctvCamera } from "react-icons/gi";
import { FaAndroid } from "react-icons/fa";
import TabBody from "components/paginationTables/withOutOnRow";

const { TabPane } = Tabs;

class DeviceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loader: false,
    };
  }

  render() {
    const { tabChange, cameraProps, kioskProps } = this.props;
    return (
      <div
        style={{
          overflowX: "auto",
          width: "100%",
          paddingTop: "10px",
          borderTop: "1px solid #efefef",
        }}
      >
        <Tabs onChange={(value) => tabChange({ value, name: "type" })}>
          <TabPane
            tab={
              <span>
                <GiCctvCamera style={{ marginRight: "8px" }} />
                CAMERA{" "}
                {cameraProps.cameradata.length > 0
                  ? `(${cameraProps.cameradata.length})`
                  : ""}
              </span>
            }
            key="cameras"
          >
            <TabBody {...cameraProps} />
          </TabPane>

          <TabPane
            tab={
              <span>
                <FaAndroid style={{ marginRight: "8px" }} />
                KIOSK{" "}
                {kioskProps.kioskdata.length > 0
                  ? `(${kioskProps.kioskdata.length})`
                  : ""}
              </span>
            }
            key="kiosks"
          >
            <TabBody {...kioskProps} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default DeviceTable;
