import React, { Component } from "react";
import DialogForm from "hoc/dialogForm";
import Form from "./Form";
import { webSocketHost } from "config";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = null;
    this.rosSocket = new WebSocket(webSocketHost);
    this.state = {
      submitted: false,
      open: false,
      reschedule_data: "",
    };
  }

  handleChange = (e) => {
    let removeT = e.target?.value?.split("T");
    let date = removeT[0];
    let time = removeT[1];
    this.setState({ reschedule_data: `${date}T${time}` });
  };

  onSubmit = () => {
    const { schedule_update } = this.props;
    let removeT = this.state.reschedule_data?.split("T");
    let date = removeT[0];
    let time = removeT[1];
    this.rosSocket.send(
      `{"channel":"non-ros","msg_type":"request","type":"service","service":"command","data":{"request_for":"submitting_reschedule","mode":"save","type":"reschedule","command":{"master_sched_id":"${schedule_update.schedule_id}","schedule_inst_id":"${schedule_update.id}","new_schedule":{"date":"${date}","time":"${time}:00"}}}}`
    );
    this.rosSocket.onmessage = (e) => {
      let parsedData = JSON.parse(e.data);
      let parsedResponse = JSON.parse(parsedData.response);
      if (
        parsedResponse.request_for === "submitting_reschedule" &&
        parsedResponse.result
      ) {
        this.props.handleSubmitSuccess();
      } else {
        this.props.onCancel();
      }
    };
  };

  componentDidMount = () => {
    this.rosSocket.onopen = () => {
      this.rosSocket.send(
        '{"channel":"non-ros","msg_type" : "request","type" : "topic","topic" : "feedback"}'
      );
    };
    const { schedule_update } = this.props;
    let removeT = schedule_update.schedule.split("T");
    let remove_ = removeT[0].split("_");
    let date = remove_[0];
    let time = remove_[1];
    if (schedule_update) {
      this.id = schedule_update.id;
      this.setState({
        reschedule_data: `${date}T${time}`,
      });
    }
  };

  render() {
    const { handleChange } = this;
    const { reschedule_data } = this.state;
    const props = { reschedule_data };
    return <Form handleChange={handleChange} {...props} />;
  }
}
export default DialogForm("Schedule")(CRUD);
