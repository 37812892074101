import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { MdLens } from "react-icons/md";
import { FaCar } from "react-icons/fa";
import { GiCctvCamera, GiPerson } from "react-icons/gi";

import { formatTime, titleize } from "helpers";
import { Col, Select, Button, Modal } from "antd";
import moment from "moment";
import { updateResolved } from "api/alerts";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { AlertResolveOptions, SpecialVehicleResolve } from "config";

const { Option } = Select;

const VehicleTimeLineDetail = (props) => {
  const { data, refreshData } = props;
  const color = data.status === "Raised" ? "red" : "green";
  let unit_num = data.entity?.block?.split(",");
  return (
    <VerticalTimeline>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        iconStyle={{ background: "#9c98f3", color: "#fff" }}
        icon={<MdLens fontSize="small" />}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 className="vertical-timeline-element-title">Triggered At</h3>
          <h4 className="vertical-timeline-element-subtitle">
            {moment(data.from_time).format("DD/MM/YYYY-HH:mm")}
          </h4>
        </div>
        <p style={{ fontWeight: "bold", margin: "10px 0px" }}>
          {data && data.description}
        </p>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h3
            style={{
              fontFamily: "Merge One",
              color: color,
              display: "flex",
              alignItems: "center",
              fontSize: "19px",
            }}
          >
            Alert Status
          </h3>
          <AlertStatus
            status={data.status}
            id={data.id}
            refreshData={refreshData}
            type={data.type}
          />
        </div>
      </VerticalTimelineElement>
      {(data.entity?.vehicle?.owner ||
        data.entity?.mode_of_entry ||
        data.entity?.block) && (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            iconStyle={{ background: "#9c98f3", color: "#fff" }}
            icon={<FaCar fontSize="small" />}
          >
            {data.entity?.vehicle?.owner &&
              Object.keys(data.entity?.vehicle?.owner).length > 0 && (
                <div className="alert-table-div">
                  <table className="alert-table">
                    <tr>
                      <th colSpan="2" className="alert-table-th-td">
                        Person Details
                      </th>
                    </tr>
                    {data.entity?.vehicle?.owner?.full_name && (
                      <tr>
                        <td
                          className="alert-table-th-td"
                          style={{ color: color }}
                        >
                          Name
                        </td>
                        <td className="alert-table-th-td">
                          {data.entity?.vehicle?.owner?.full_name}
                        </td>
                      </tr>
                    )}
                    {data.entity?.vehicle?.owner?.phone && (
                      <tr>
                        <td
                          className="alert-table-th-td"
                          style={{ color: color }}
                        >
                          Phone
                        </td>
                        <td className="alert-table-th-td">
                          {data.entity?.vehicle?.owner?.phone}
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              )}

            {(data.entity?.number_plate ||
              data.entity?.block ||
              data.entity?.mode_of_entry) && (
                <div className="alert-table-div">
                  <table className="alert-table">
                    <tr>
                      <th colSpan="2" className="alert-table-th-td">
                        Entry Details
                      </th>
                    </tr>
                    {data.entity?.number_plate && (
                      <tr>
                        <td className="alert-table-th-td" style={{ color: color }}>
                          Number Plate
                        </td>
                        <td className="alert-table-th-td">
                          {data.entity?.number_plate}
                        </td>
                      </tr>
                    )}
                    {data.entity?.mode_of_entry && (
                      <tr>
                        <td className="alert-table-th-td" style={{ color: color }}>
                          Mode of Entry
                        </td>
                        <td className="alert-table-th-td">
                          {titleize(data.entity?.mode_of_entry)}
                        </td>
                      </tr>
                    )}

                    {unit_num?.map((number, i) => (
                      <tr key={i}>
                        <td className="alert-table-th-td" style={{ color: color }}>
                          Block
                        </td>
                        <td className="alert-table-th-td">{number}</td>
                      </tr>
                    ))}

                    {data.entity?.in_time && (
                      <tr>
                        <td className="alert-table-th-td" style={{ color: color }}>
                          Entry Time
                        </td>
                        <td className="alert-table-th-td">
                          {formatTime(data.entity?.in_time)}
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              )}
          </VerticalTimelineElement>
        )}

      {data.entity !== null && data.entity?.visitors?.length > 0 && (
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: "#9c98f3", color: "#fff" }}
          icon={<GiPerson fontSize="small" />}
        >
          {data.entity?.visitors?.length > 0 && (
            <div className="alert-table-div">
              <table className="alert-table">
                <tr>
                  <th colSpan="2" className="alert-table-th-td">
                    Visitor Entry
                  </th>
                </tr>
                {data.entity?.visitors?.length > 0 &&
                  data.entity?.visitors.map((visit) => (
                    <>
                      {visit?.name && (
                        <tr>
                          <td
                            className="alert-table-th-td"
                            style={{ color: color }}
                          >
                            Name
                          </td>
                          <td className="alert-table-th-td">{visit.name}</td>
                        </tr>
                      )}
                      {visit?.phone && (
                        <tr>
                          <td
                            className="alert-table-th-td"
                            style={{ color: color }}
                          >
                            Phone
                          </td>
                          <td className="alert-table-th-td">{visit.phone}</td>
                        </tr>
                      )}
                    </>
                  ))}
                {data.entity?.visitor_type && (
                  <tr>
                    <td className="alert-table-th-td" style={{ color: color }}>
                      Visitor Type
                    </td>
                    <td className="alert-table-th-td">
                      {data.entity?.visitor_type}
                    </td>
                  </tr>
                )}
              </table>
            </div>
          )}
        </VerticalTimelineElement>
      )}

      {data.entity?.vehicle_entry?.vehicle_trackings.length > 0 && (
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: "#9c98f3", color: "#fff" }}
          icon={<GiCctvCamera fontSize="small" />}
        >
          {data.entity?.vehicle_entry &&
            Object.keys(data.entity?.vehicle_entry)?.length > 0 && (
              <div className="alert-table-div">
                <table className="alert-table">
                  <tr>
                    <th colSpan="2" className="alert-table-th-td">
                      Camera Details
                    </th>
                  </tr>
                  {data.entity?.vehicle_entry?.vehicle_trackings?.map(
                    (cam, i) => (
                      <tr key={i}>
                        <td
                          className="alert-table-th-td"
                          style={{
                            color: data.status === "Raised" ? "red" : "green",
                          }}
                        >
                          {cam.camera_name}
                        </td>
                        <td className="alert-table-th-td">
                          {formatTime(cam.detected_time)}
                        </td>
                      </tr>
                    )
                  )}
                </table>
              </div>
            )}
        </VerticalTimelineElement>
      )}

      {data.entity?.camera && (
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: "#9c98f3", color: "#fff" }}
          icon={<GiCctvCamera fontSize="small" />}
        >
          {data.entity?.camera && (
            <div className="alert-table-div">
              <table className="alert-table">
                <tr>
                  <th colSpan="2" className="alert-table-th-td">
                    Camera Entry
                  </th>
                </tr>
                <tr>
                  <td className="alert-table-th-td" style={{ color: color }}>
                    Camera Name
                  </td>
                  <td className="alert-table-th-td">
                    {data.entity?.camera?.name}
                  </td>
                </tr>
              </table>
            </div>
          )}
        </VerticalTimelineElement>
      )}
    </VerticalTimeline>
  );
};
export default VehicleTimeLineDetail;

class AlertStatus extends Component {
  constructor(props) {
    super(props);
    this.SpecialVehicle = this.props.type === "special_vehicle.entry" ? SpecialVehicleResolve : AlertResolveOptions
    this.state = {
      resolve_msg: this.SpecialVehicle[0],
      remark_msg: "",
      visible: false,
    };
  }
  handleSubmit = () => {
    const { resolve_msg, remark_msg } = this.state;
    const remark_message =
      remark_msg.length === 0 ? resolve_msg : `${resolve_msg} : ${remark_msg}`;
    updateResolved(this.props.id, remark_message)
      .then(() => {
        success("Updated Successfully..!");
        this.setState({
          resolve_msg: this.SpecialVehicle[0],
          visible: false,
          remark_msg: "",
        });
        setTimeout(() => {
          this.props.refreshData();
        }, 1000);
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleChange = (e) => {
    this.setState({ remark_msg: e.target.value });
  };

  handleChangeSelect = (value) => {
    this.setState({ resolve_msg: value, remark_msg: "" });
  };
  handleClick = () => {
    if (this.props.status === "Raised") {
      this.setState({ visible: true });
    } else {
      this.handleCancel();
    }
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const { status, type } = this.props;
    const { remark_msg, resolve_msg, visible } = this.state;
    const {
      handleChange,
      handleChangeSelect,
      handleClick,
      handleCancel,
      handleSubmit,
    } = this;
    const backgroundColor = status === "Raised" ? "#E50027" : "#348302";
    const text = status === "Raised" ? "Open" : "Closed";
    const req =
      resolve_msg === "Others" || resolve_msg === "Wrong Entry" ? true : false;

    return (
      <Col
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Button
          onClick={type && handleClick}
          type="primary"
          style={{ background: backgroundColor, borderColor: backgroundColor }}
        >
          {text}
        </Button>
        <Modal
          visible={visible}
          closable={false}
          width="30%"
          title="Resolve Alert"
          footer={[
            <Button
              variant="contained"
              onClick={handleCancel}
              key={"1"}
              style={{
                color: "white",
                backgroundColor: "#748892",
                borderRadius: "4px",
              }}
            >
              Cancel
            </Button>,
            <Button
              variant="contained"
              disabled={req ? (remark_msg.length === 0 ? true : false) : false}
              onClick={handleSubmit}
              key={"2"}
              style={{
                color: "white",
                backgroundColor: "#27a243",
                borderRadius: "4px",
              }}
              autoFocus
            >
              Submit
            </Button>,
          ]}
        >
          <div
            style={{
              textAlign: "center",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: "normal" }}>
              Comment the reason why this alert should be resolved
            </p>
            <Select
              value={resolve_msg}
              defaultValue={this.SpecialVehicle[0]}
              onChange={(e) => handleChangeSelect(e)}
              style={{ width: "80%" }}
            >
              {this.SpecialVehicle.map((o, i) => {
                return (
                  <Option key={i} value={o}>
                    {o}
                  </Option>
                );
              })}
            </Select>
            <div style={{ marginTop: "10px" }}>
              <SimpleTextInput
                labelText="Remarks"
                name="remark_msg"
                value={remark_msg}
                onChange={handleChange}
                multi={true}
                rows={2}
                required={req}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              padding: "0px 0px 10px",
            }}
          ></div>
        </Modal>
      </Col>
    );
  }
}
