import React from "react";
import { Modal, Descriptions } from "antd";
import { presence,titleize } from "helpers";
import { MdOpenInNew } from "react-icons/md";
import moment from "moment";

export const Form = ({
  invitees,
  invitor,
  purpose,
  remarks,
  visiting_time,
  onClose,
  inviteeDetails,
  created_at,
  status
}) => {
    return (
    <React.Fragment>
      <Modal
        title="Invitation Details"
        rowData
        visible={true}
        width={750}
        footer={null}
        onCancel={onClose}
      >
        <Descriptions
          title={"Registration Details"}
          layout="horizontal"
          bordered
          colon
          column={2}
          style={{
            marginBottom: "30px",
            fontSize: "18px",
            fontWeight: "normal",
            padding: "0px 24px",
          }}
        >
          <Descriptions.Item label={"Created At"}>
            {moment(created_at).format("MMM DD, HH:mm:ss")}
          </Descriptions.Item>
          <Descriptions.Item label={"Visiting Time"}>
            {moment(visiting_time).format("MMM DD, HH:mm:ss")}
          </Descriptions.Item>
          <Descriptions.Item label={"Current Status"}>
            {presence(titleize(status))}
          </Descriptions.Item>
          <Descriptions.Item label={"Purpose of Visit"}>
            {presence(purpose)}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          title={"Visitor Details"}
          layout="horizontal"
          bordered
          colon
          column={2}
          style={{
            marginBottom: "30px",
            fontSize: "18px",
            fontWeight: "normal",
            padding: "0px 24px",
          }}
        >
          {invitees.map((invite) => (
            <Descriptions.Item key={invite.id} label={"Name"}>
              {presence(titleize(invite.name))}
            </Descriptions.Item>
          ))}
          {invitees.map((invite) => (
            <Descriptions.Item key={invite.id} label={"Phone"}>
              {presence(invite.phone)}
            </Descriptions.Item>
          ))}
          {invitees.map((invite) => (
            <Descriptions.Item key={invite.id} label={"Visitor Status"}>
              {presence(invite.status)}
            </Descriptions.Item>
          ))}

          {invitees.map((invite) => (
            <Descriptions.Item key={invite.id} label={"Action"}>
              {invite.status === "visited" ? (
                <MdOpenInNew
                  style={{
                    color: "#0097d2",
                    fontSize: "25px",
                    padding: "2px",
                    cursor: "pointer",
                  }}
                  onClick={() => inviteeDetails(invite)}
                />
              ) : (
                <p style={{ display: "contents" }}>-</p>
              )}
            </Descriptions.Item>
          ))}
          {invitees.map((invite) => (
            <Descriptions.Item key={invite.id} label={"Visited at"}>
              {invite.visited_at
                ? moment(visiting_time && visiting_time).format(
                    "MMM DD, HH:mm:ss"
                  )
                : presence(invite.visited_at)}
            </Descriptions.Item>
          ))}
        </Descriptions>
        <Descriptions
          title={"Invitor Details"}
          layout="horizontal"
          bordered
          colon
          column={2}
          style={{
            marginBottom: "30px",
            fontSize: "18px",
            fontWeight: "normal",
            padding: "0px 24px",
          }}
        >
          <Descriptions.Item label={"Name"}>
            {presence(titleize(invitor?.name))}
          </Descriptions.Item>
          <Descriptions.Item label={"Phone"}>
            {presence(invitor?.phone)}
          </Descriptions.Item>
          <Descriptions.Item label={"Remarks"}>
            {presence(remarks)}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </React.Fragment>
  );
};
