import React from "react";
import { Button } from "antd";
import moment from "moment";

export const columns = (data, editSchedule, updateAction, deleteSchedule) => {
  return [
    {
      title: "Plan Name",
      dataIndex: "plan",
      render: (plan_name) => {
        return plan_name;
      },
      align: "center",
    },
    {
      title: "Scheduled at",
      dataIndex: "schedule",
      render: (start_time) => {
        let dateAndTime = start_time.split("_");
        return `${moment(dateAndTime[0]).format("DD,MMM,YY")} - ${
          dateAndTime[1]
        }`;
      },
      align: "center",
    },
    {
      title: "Manage",
      render: (row) => {
        return (
          <React.Fragment>
            {updateAction && (
              <Button
                onClick={() => editSchedule(row)}
                style={{
                  margin: "0px 10px 5px 10px",
                }}
              >
                Edit
              </Button>
            )}
            <Button
              onClick={() => deleteSchedule(row)}
              style={{
                margin: "0px 10px 5px 10px",
                backgroundColor: "#e31e1e",
                color: "#fff",
              }}
            >
              Delete
            </Button>
          </React.Fragment>
        );
      },
      align: "center",
    },
  ];
};
