import React from "react";
import {
  FaUserCog,
  FaUserTie,
  FaTruckMoving,
  FaTaxi,
  FaCar,
  FaUserCheck,
  FaUsers,
  FaRegChartBar,
  FaUserShield,
  FaRegBell,
  FaRegStickyNote,
  FaMailBulk,
  FaRegRegistered,
  FaCarSide,
  FaAddressCard,
} from "react-icons/fa";
import { FiHome, FiAlertTriangle, FiCamera } from "react-icons/fi";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { GiJumpAcross, GiVintageRobot, GiEntryDoor } from "react-icons/gi";
import { AiFillAlert } from "react-icons/ai";
import {
  MdBlock,
  MdDashboard,
  MdLiveTv,
  MdImportantDevices,
  MdMemory,
  MdNaturePeople,
  MdSchedule,
} from "react-icons/md";
import { ImNext } from "react-icons/im";
import { GoVerified } from "react-icons/go";
import {
  Home,
  Vehicles,
  BlackLists,
  VehicleEntries,
  PeopleEntries,
  VisitorInvites,
  Alerts,
  Analytics,
  Users,
  Notifications,
  Reports,
  SentAlerts,
  Outsiders,
  CardRegister,
  Announcements,
  Permissions,
  Softwares,
  AlertScreen,
  AlertTypes,
  Devices,
  Employees,
  Staffs,
  Schedulers,
  PeopleCount,
  SmVehicle,
  Contractorcard,
  Upcoming,
  Completed,
  EmployeeEntries,
} from "views";
import AlertOverview from "views/Alerts-Module/index";

import Units from "../views/Units";

import {
  VisitorEntries,
  ContractorEntries,
  DeliveryEntries,
  PickupEntries,
} from "data/peopleEntries";

import {
  SosAlerts,
  ParkingUnauthorized,
  OccupantAlerts,
  PerimeterAlerts,
  OtherAlerts,
  CameraAlerts,
  RobotAlerts,
} from "config";

import { fetchUserRoles } from "api/users";
import { FaEllipsisV, FaFacebookMessenger } from "react-icons/fa";
import { handleResponse } from "components/notifications/handleResponse";

const wrapHOC = (WrappedComponent) => {
  return <WrappedComponent style={{ fontSize: 22 }} />;
};

const Promise1 = new Promise((res) => {
  try {
    const data = JSON.parse(window.localStorage.getItem("user_roles"));
    if (!data || "object" !== typeof data) {
      res([false]);
    }
    res([true, data]);
  } catch (err) {
    res([false]);
  }
});

let parking_alert_type = [];
let alert_group = [];

ParkingUnauthorized &&
  ParkingUnauthorized.type &&
  ParkingUnauthorized.type.forEach((i) => parking_alert_type.push(i));
SosAlerts &&
  SosAlerts.type &&
  SosAlerts.type.forEach((i) => alert_group.push(i));
PerimeterAlerts &&
  PerimeterAlerts.type &&
  PerimeterAlerts.type.forEach((i) => alert_group.push(i));
OccupantAlerts &&
  OccupantAlerts.type &&
  OccupantAlerts.type.forEach((i) => alert_group.push(i));

let not_alert_group = [];
let not_alert_type = [];
[ParkingUnauthorized, SosAlerts, PerimeterAlerts, OccupantAlerts].forEach(
  (alert) => {
    if (alert.type?.length) {
      alert.type.forEach((type) => not_alert_type.push(type));
    }
    if (alert.group?.length) {
      alert.group.forEach((group) => not_alert_group.push(group));
    }
  }
);

const Others = {
  title: OtherAlerts.title,
  subtitle: OtherAlerts.subtitle,
  not_alert_group,
  not_alert_type,
};

export const filteredMenu = async () => {
  try {
    let [status, userRoles] = await Promise1;
    if (!status) {
      await fetchUserRoles()
        .then(({ data }) => {
          userRoles = data;
          window.localStorage.setItem("user_roles", JSON.stringify(userRoles));
        })
        .catch(async (error) => {
          handleResponse(error);
        });
    }

    let result = [...menuGroups];

    menuGroups.map((menuGroup, i) => {
      let yy = menuGroup?.children.filter((menus, j) => {
        let xx = menus?.children?.filter(
          (menu) =>
            !(menu?.show === false) &&
            Object.keys(menu.permissions).every(
              (permission) =>
                Object.keys(userRoles).includes(permission) &&
                menu.permissions[permission].every((sub_permission) =>
                  userRoles[permission].includes(sub_permission)
                )
            )
        );
        result[i].children[j].children = xx;
        return !xx.length ? false : xx;
      });

      result[i].children = yy;
      return !yy.length ? false : yy;
    });

    result = result.filter((x) => !!x.children.length);
    return result;
  } catch (err) {
    console.log(err);
  }
};

const menuGroups = [
  {
    name: "Visitor Management",
    index: 1,
    children: [
      {
        name: "Overview",
        children: [
          {
            index: "1-0",
            name: "Dashboard",
            icon: wrapHOC(MdDashboard),
            url: () => "/bms-dashboard",
            match_url: "/bms-dashboard",
            render: (props) => <Home {...props} />,
            permissions: {
              "api/v1/vehicle_entries": ["type_of_entries", "recent", "tiles"],
              // "api/v1/visitors": ["nature_of_visitors"]
            },
            slideShow: true,
          },
        ],
      },
      {
        name: "Entry Logs",
        children: [
          {
            index: "1-2",
            name: "LNPR Records",
            icon: wrapHOC(FaCar),
            url: () => "/vehicle-entries",
            match_url: "/vehicle-entries",
            render: (props) => <VehicleEntries {...props} />,
            permissions: {
              "api/v1/cameras": ["index"],
              "api/v1/vehicle_entries": ["index"],
            },
            slideShow: true,
          },
          {
            index: "1-3",
            name: "Visitor",
            icon: wrapHOC(FaUserTie),
            url: () => "/visitor-entries/guest",
            match_url: "/visitor-entries/guest",
            render: (props) => (
              <PeopleEntries {...props} childProps={VisitorEntries} />
            ),
            permissions: {
              "api/v1/visits": ["index"],
            },
            slideShow: true,
          },
          {
            index: "1-4",
            name: "Contractor",
            icon: wrapHOC(FaUserCog),
            url: () => "/visitor-entries/contractor",
            match_url: "/visitor-entries/contractor",
            render: (props) => (
              <PeopleEntries {...props} childProps={ContractorEntries} />
            ),
            permissions: {
              "api/v1/visits": ["index"],
            },
            slideShow: true,
          },
          {
            index: "1-8",
            name: "Invitation",
            icon: wrapHOC(FaMailBulk),
            url: () => "/invitations",
            match_url: "/invitations",
            render: (props) => <VisitorInvites {...props} />,
            permissions: {
              "api/v1/invites": ["index"],
            },
            slideShow: true,
          },

          {
            index: "1-5",
            name: "Delivery",
            icon: wrapHOC(FaTruckMoving),
            url: () => "/visitor-entries/delivery",
            match_url: "/visitor-entries/delivery",
            render: (props) => (
              <PeopleEntries {...props} childProps={DeliveryEntries} />
            ),
            permissions: {
              "api/v1/visits": ["index"],
            },
            slideShow: true,
            show: false,
          },
          {
            index: "1-6",
            name: "Pickup",
            icon: wrapHOC(FaTaxi),
            url: () => "/visitor-entries/pickup",
            match_url: "/visitor-entries/pickup",
            render: (props) => (
              <PeopleEntries {...props} childProps={PickupEntries} />
            ),
            permissions: {
              "api/v1/visits": ["index"],
            },
            slideShow: true,
            show: false,
          },
          {
            index: "1-7",
            name: "Employee Entry",
            icon: wrapHOC(GiEntryDoor),
            url: () => "/employee-entries",
            match_url: "/employee-entries",
            render: (props) => <EmployeeEntries {...props} />,
            permissions: {
              "api/v1/employee_entries": ["index"],
            },
            slideShow: true,
            show: true,
          },
        ],
      },

      {
        name: "Analytics",
        children: [
          {
            index: "1-9",
            name: "Analytics",
            icon: wrapHOC(FaRegChartBar),
            url: () => "/analytics",
            match_url: "/analytics",
            render: (props) => <Analytics {...props} />,
            permissions: {
              "api/v1/cameras": ["index"],
              "api/v1/vehicle_entries": ["traffic_flow"],
            },
            slideShow: true,
            show: false,
          },
          {
            index: "1-10",
            name: "People Count",
            icon: wrapHOC(FaRegChartBar),
            url: () => "/people_count",
            match_url: "/people_count",
            render: (props) => <PeopleCount {...props} />,
            permissions: {
              "api/v1/people_counts": ["tiles", "recent_records"],
            },
            slideShow: false,
            show: false,
          },
        ],
      },
    ],
  },
  {
    name: "Alert Management",
    index: 2,
    children: [
      {
        name: "Alerts-Module",
        children: [
          {
            index: "2-0",
            name: "Presentation",
            icon: wrapHOC(MdLiveTv),
            url: () => "/alerts/overview",
            match_url: "/alerts/overview",
            render: (props) => <AlertOverview {...props} />,
            permissions: {
              "api/v1/alerts": ["index", "aggs"],
              "api/v1/alert_types": ["index"],
              "api/v1/presentations": ["index"],
            },
            slideShow: false,
            show: ParkingUnauthorized.show,
          },
          {
            index: "2-1",
            name: "SoS",
            icon: wrapHOC(FiAlertTriangle),
            url: () => "/alerts/emergency_alerts",
            match_url: "/alerts/emergency_alerts",
            render: (props) => <Alerts {...props} alertProps={SosAlerts} />,
            permissions: {
              "api/v1/alerts": ["index"],
              "api/v1/alert_types": ["index"],
            },
            slideShow: true,
            show: SosAlerts.show,
          },
          {
            index: "2-2",
            name: "Parking Alerts",
            icon: wrapHOC(MdBlock),
            url: () => "/alerts/parking_unauthorized",
            match_url: "/alerts/parking_unauthorized",
            render: (props) => (
              <Alerts {...props} alertProps={ParkingUnauthorized} />
            ),
            permissions: {
              "api/v1/alerts": ["index"],
              "api/v1/alert_types": ["index"],
            },
            slideShow: true,
            show: ParkingUnauthorized.show,
          },
          {
            index: "2-4",
            name: "People Occupant",
            icon: wrapHOC(FaUserShield),
            url: () => "/alerts/people_occupant",
            match_url: "/alerts/people_occupant",
            render: (props) => (
              <Alerts {...props} alertProps={OccupantAlerts} />
            ),
            permissions: {
              "api/v1/alerts": ["index"],
              "api/v1/alert_types": ["index"],
            },
            slideShow: true,
            show: false,
            // show: OccupantAlerts.show,
          },
          {
            index: "2-5",
            name: "Perimeter Crossing",
            icon: wrapHOC(GiJumpAcross),
            url: () => "/alerts/perimeter_crossing",
            match_url: "/alerts/perimeter_crossing",
            render: (props) => (
              <Alerts {...props} alertProps={PerimeterAlerts} />
            ),
            permissions: {
              "api/v1/alerts": ["index"],
              "api/v1/alert_types": ["index"],
            },
            slideShow: true,
            show: false,

            //show: PerimeterAlerts.show,
          },
          {
            index: "2-7",
            name: "Camera Alerts",
            icon: wrapHOC(FiCamera),
            url: () => "/alerts/camera_alerts",
            match_url: "/alerts/camera_alerts",
            render: (props) => <Alerts {...props} alertProps={CameraAlerts} />,
            permissions: {
              "api/v1/alerts": ["index"],
              "api/v1/alert_types": ["index"],
            },
            slideShow: true,
            show: CameraAlerts.show,
          },
          {
            index: "2-8",
            name: "Robot Alerts",
            icon: wrapHOC(GiVintageRobot),
            url: () => "/alerts/robert_alerts",
            match_url: "/alerts/robert_alerts",
            render: (props) => <Alerts {...props} alertProps={RobotAlerts} />,
            permissions: {
              "api/v1/alerts": ["index"],
              "api/v1/alert_types": ["index"],
            },
            slideShow: true,
            show: RobotAlerts.show,
          },
          {
            index: "2-6",
            name: "Others",
            icon: wrapHOC(FaEllipsisV),
            url: () => "/alerts/others",
            match_url: "/alerts/others",
            render: (props) => <Alerts {...props} alertProps={Others} />,
            permissions: {
              "api/v1/alerts": ["index"],
              "api/v1/alert_types": ["index"],
            },
            slideShow: true,
            show: OtherAlerts.show,
          },
        ],
      },
    ],
  },

  {
    name: "Master DB",
    index: 3,
    children: [
      {
        name: "Database",
        children: [
          {
            index: "3-0",
            name: "Company",
            icon: wrapHOC(FaUserCheck),
            url: () => "/company",
            match_url: "/company",
            render: (props) => <Staffs {...props} />,
            permissions: {
              "api/v1/staffs": ["index"],
            },
            slideShow: true,
          },
          {
            index: "3-0",
            name: "Other Staffs",
            icon: wrapHOC(MdNaturePeople),
            url: () => "/employees",
            match_url: "/employees",
            render: (props) => <Employees {...props} />,
            permissions: {
              "api/v1/employees": ["index"],
            },
            slideShow: true,
          },

          {
            index: "3-1",
            name: "Outsiders",
            icon: wrapHOC(FaUsers),
            url: () => "/outsiders",
            match_url: "/outsiders",
            render: (props) => <Outsiders {...props} />,
            permissions: {
              "api/v1/visitors": ["index"],
            },
            slideShow: true,
          },

          {
            index: "3-2",
            name: "Vehicles",
            icon: wrapHOC(FaCar),
            url: () => "/vehicles",
            match_url: "/vehicles",
            render: (props) => <Vehicles {...props} />,
            permissions: {
              "api/v1/vehicles": ["index"],
            },
            slideShow: true,
          },
          {
            index: "3-3",
            name: "BlackLists",
            icon: wrapHOC(MdBlock),
            url: () => "/blacklists",
            match_url: "/blacklists",
            render: (props) => <BlackLists {...props} />,
            permissions: {
              "api/v1/blacklists": ["index"],
            },
            slideShow: true,
          },

          {
            index: "3-4",
            name: "SM Vehicles",
            icon: wrapHOC(FaCarSide),
            url: () => "/senior_management_vehicle",
            match_url: "/senior_management_vehicle",
            render: (props) => <SmVehicle {...props} />,
            permissions: {
              "api/v1/sm_vehicles": ["index"],
            },
            slideShow: true,
          },

          {
            index: "1-11",
            name: "Contractor Cards",
            icon: wrapHOC(FaAddressCard),
            url: () => "/visitor-entries/contractor-card",
            match_url: "/visitor-entries/contractor-card",
            render: (props) => <Contractorcard {...props} />,
            permissions: {
              "api/v1/contractor_cards": ["index"],
            },
            slideShow: true,
          },
          {
            index: "3-5",
            name: "Contractor",
            icon: wrapHOC(FaRegRegistered),
            url: () => "/register-contractor",
            match_url: "/register-contractor",
            render: (props) => <CardRegister {...props} />,
            permissions: {
              "api/v1/contractors": ["index"],
            },
            slideShow: true,
          },
          {
            index: "2-5",
            name: "Announcements",
            icon: wrapHOC(HiOutlineSpeakerphone),
            url: () => "/announcements",
            match_url: "/announcements",
            render: (props) => <Announcements {...props} />,
            permissions: {
              "api/v1/announcements": ["index"],
              "api/v1/roles": ["index"],
            },
            slideShow: true,
            show: false,
          },
          {
            index: "2-6",
            name: "Units",
            icon: wrapHOC(FiHome),
            url: () => "/units",
            match_url: "/units",
            render: (props) => <Units {...props} />,
            permissions: {
              "api/v1/units": ["list"],
            },
            slideShow: true,
            show: false,
          },
        ],
      },
    ],
  },

  {
    name: "Admin Panel",
    index: 4,
    children: [
      {
        name: "User Management",
        children: [
          {
            index: "4-0",
            name: "Users",
            icon: wrapHOC(FaUserShield),
            url: () => "/users",
            match_url: "/users",
            render: (props) => <Users {...props} />,
            permissions: {
              "api/v1/users": ["index"],
            },
            slideShow: true,
          },
          {
            index: "4-1",
            name: "Notifications",
            icon: wrapHOC(FaRegBell),
            url: () => "/notifications",
            match_url: "/notifications",
            render: (props) => <Notifications {...props} />,
            permissions: {
              "api/v1/notifications": ["index"],
            },
            slideShow: true,
          },
          {
            index: "4-2",
            name: "Reports",
            icon: wrapHOC(FaRegStickyNote),
            url: () => "/reports",
            match_url: "/reports",
            render: (props) => <Reports {...props} />,
            permissions: {
              "api/v1/reports": ["index"],
            },
            slideShow: true,
          },
          {
            index: "4-3",

            name: "Send Alerts",
            icon: wrapHOC(FaFacebookMessenger),
            url: () => `/sent-alerts`,
            match_url: "/sent-alerts",
            render: (props) => <SentAlerts {...props} />,
            show: true,
            permissions: {
              "api/v1/sent_alerts": ["index", "show"],
            },
            slideShow: true,
          },
          {
            index: "4-3",
            name: "Permission",
            icon: wrapHOC(FaUserShield),
            url: () => "/permissions",
            match_url: "/permissions",
            render: (props) => <Permissions {...props} />,
            permissions: {
              "api/v1/permissions": ["index"],
            },
            slideShow: false,
            show: false,
          },
          {
            index: "4-4",
            name: "Devices",
            icon: wrapHOC(MdImportantDevices),
            url: () => `/devices`,
            match_url: "/devices",
            render: (props) => <Devices {...props} />,
            show: true,
            permissions: {
              "api/v1/cameras": ["index"],
              "api/v1/kiosks": ["index"],
            },
            slideShow: true,
          },
          {
            index: "4-5",
            name: "Softwares",
            icon: wrapHOC(MdMemory),
            url: () => "/softwares",
            match_url: "/softwares",
            render: (props) => <Softwares {...props} />,
            permissions: {
              "api/v1/softwares": ["index"],
            },
            slideShow: true,
          },
          {
            index: "4-6",
            name: "Scheduler",
            icon: wrapHOC(MdSchedule),
            url: () => `/schedulers`,
            match_url: "/schedulers",
            render: (props) => <Schedulers {...props} />,
            permissions: {
              "api/v1/schedulers": ["index"],
            },
            slideShow: true,
            show: false,
          },

          {
            index: "4-6",
            name: "Alert Types",
            icon: wrapHOC(AiFillAlert),
            url: () => `/alert-types`,
            match_url: "/alert-types",
            render: (props) => <AlertTypes {...props} />,
            permissions: {
              "api/v1/alert_types": ["index"],
            },
            slideShow: true,
          },
          {
            index: "4-7",
            name: "Alert Screen",
            icon: wrapHOC(MdLiveTv),
            url: () => `/presentations`,
            match_url: "/presentations",
            render: (props) => <AlertScreen {...props} />,
            permissions: {
              "api/v1/presentations": ["index", "update"],
            },
            slideShow: true,
          },
        ],
      },
    ],
  },
  {
    name: "Patrolling Robot",
    index: 5,
    children: [
      {
        name: "Schedules",
        children: [
          {
            index: "5-0",
            name: "Upcoming",
            icon: wrapHOC(ImNext),
            url: () => `/schedules/upcoming`,
            match_url: "/schedules/upcoming",
            render: (props) => <Upcoming {...props} />,
            permissions: {
              "api/v1/vehicle_entries": ["index"],
            },
            slideShow: true,
          },
          {
            index: "5-1",
            name: "Completed",
            icon: wrapHOC(GoVerified),
            url: () => `/schedules/completed`,
            match_url: "/schedules/completed",
            render: (props) => <Completed {...props} />,
            permissions: {
              "api/v1/vehicle_entries": ["index"],
            },
            slideShow: true,
          },
        ],
      },
    ],
  },
];
