import React, { Component } from "react";
import c3 from "c3";
import "c3/c3.css";
import { handleResponse } from "components/notifications/handleResponse";
import { peopleCountGraph } from "api/peoplecount";
import { AppBarContext } from "context/ContextProvider";
import { blue } from "@material-ui/core/colors";
import { Card, CardHeader } from "material-dashboard-react/components/index";
class PeopleTraffic extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      data: [],
      menuOpen: false,
    };
  }

  async componentDidMount() {
    await this.fetchGraphData();
    this.generateChart();
    await this.reloadChart();
  }

  generateChart = () => {
    this.chart = c3.generate({
      bindto: "#people-count",
      data: {
        xFormat: "%Y-%m-%d %H:%M:%S%Z",
        json: [],
      },
      size: {
        height: 380,
      },
      axis: {
        x: {
          type: "timeseries",
          show: true,
          tick: {
            fit: true,
            format: "%H:%M-%m",
          },
        },
        y: {
          min: 0,
          padding: { top: 10, bottom: 0 },
        },
      },
    });
  };

  fetchGraphData = async () => {
    await peopleCountGraph()
      .then(({ data }) => {
        this.setState({
          data,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  reloadChart = () => {
    let data = this.state.data;
    let display_names = Object.keys(data[0]);
    display_names.splice(display_names.indexOf("datetime"), 1);
    this.chart.internal.config.axis_x_tick_format = "%e-%b";
    this.chart.flush();
    this.chart.load({
      xFormat: "%Y-%m-%d %H:%M:%S%Z",
      json: data,
      type: "bar",
      keys: {
        x: "datetime",
        value: display_names,
      },
    });
  };

  render() {
    return (
      <Card style={{ marginBottom: "0px" }}>
        <CardHeader
          style={{
            marginTop: " -20px",
            height: "85px",
            marginRight: " 20px",
            marginLeft: "20px",
            borderRadius: "3px",
            boxShadow: "#42A4F4 -1px 7px 8px -5px",
            background: `linear-gradient(60deg, ${blue[600]}, ${blue[400]})`,
          }}
        >
          <div>
            <h3
              style={{
                margin: "2px 0px 3px",
                color: "white",
                fontSize: "18px",
                fontWeight: "300",
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
              }}
            >
              People Count
            </h3>
            <p
              style={{
                color: "rgba(255,255,255,.62)",
                margin: "0 .1rem",
              }}
            >
              {" "}
              Monitoring People Count for Last 7 day{" "}
            </p>
          </div>
        </CardHeader>

        <AppBarContext.Consumer>
          {({ state: { menuOpen } }) => {
            if (menuOpen !== this.state.menuOpen) {
              setTimeout(
                function() {
                  this.generateChart();
                  this.reloadChart();
                }.bind(this),
                100 * 10
              );
              this.setState({ menuOpen });
            }
          }}
        </AppBarContext.Consumer>
        <div id="people-count" style={{ margin: "20px" }} />
      </Card>
    );
  }
}
export default PeopleTraffic;
