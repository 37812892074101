import makeRequest from ".";

function generateParams(params = {}) {
  return `query=${params.search}&page=${params.page || 1}&per_page=${
    params.perPage
  }`;
}

export function fetchStaffsData(requestParams) {
  return makeRequest({
    uri: `/api/v1/staffs?${generateParams(requestParams)}`,
  });
}

export function createStaffs(requestBody) {
  return makeRequest({
    uri: "/api/v1/staffs",
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateStaffs(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/staffs/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}
