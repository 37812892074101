import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  MdAirportShuttle,
  MdDriveEta,
  MdTraffic,
  MdUpdate,
  MdWarning,
  MdThumbUp,
  MdArrowUpward,
  MdArrowDownward,
} from "react-icons/md";
import moment from "moment";
import { Row, Col } from "antd";
import {
  Card,
  CardIcon,
  CardHeader,
  CardFooter,
} from "material-dashboard-react/components/index";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { fetchTiles } from "api/dashboard";
import { handleResponse } from "components/notifications/handleResponse";
import { dashboardTiles } from "config";
import ColIcons from "components/ColIcons";
import commute from "assets/img/commuteIcon.png";
import { GiCctvCamera } from "react-icons/gi";
import { AiOutlineCar } from "react-icons/ai";
import { FaTruckMonster } from "react-icons/fa";
import { IoIosPeople, IoMdPeople } from "react-icons/io";
import { MdToday } from "react-icons/md";

const tiles = {
  vehicles_in: {
    title: "Vehicles In",
    color: "rose",
    icon: <AiOutlineCar />,
    trend: false,
  },
  vehicles_out: {
    title: "Vehicles Out",
    color: "primary",
    icon: <MdAirportShuttle />,
    trend: false,
  },
  visitor_vehicles_in: {
    title: "Visitor Vehicles In",
    color: "warning",
    icon: <MdDriveEta />,
    trend: true,
  },
  visitor_vehicles_out: {
    title: "Visitor Vehicles Out",
    color: "rose",
    icon: <MdTraffic />,
    trend: false,
  },
  working_camera: {
    title: "Active Cameras",
    color: "primary",
    icon: <GiCctvCamera />,
    trend: false,
  },
  total_vehicles: {
    title: "Total Vehicles",
    color: "success",
    icon: <FaTruckMonster />,
    trend: false,
  },
  total_visitors: {
    title: "Total Visitors",
    color: "success",
    icon: <IoIosPeople />,
    trend: false,
  },
  total_vehicle_entries: {
    title: "Total Vehicles",
    color: "success",
    icon: <FaTruckMonster />,
    trend: false,
  },
  today_vehicles_in: {
    title: "Vehicles In",
    color: "rose",
    icon: <AiOutlineCar />,
    trend: false,
  },
  today_vehicles_out: {
    title: "Vehicles Out",
    color: "primary",
    icon: <MdAirportShuttle />,
    trend: false,
  },
  today_visitor_vehicles_in: {
    title: "Vehicles In",
    color: "rose",
    icon: <AiOutlineCar />,
    trend: false,
  },
  today_visitor_vehicles_out: {
    title: "Vehicles Out",
    color: "primary",
    icon: <MdAirportShuttle />,
    trend: false,
  },
  visitors_in: {
    title: "Vehicles Out",
    color: "primary",
    icon: <MdAirportShuttle />,
    trend: false,
  },
};
class Tiles extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.gridRef = React.createRef();
    this.state = {
      data: {},
      tiles: dashboardTiles,
    };
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.fetchTilesData();
    this.timer = setTimeout(this.autoRefresh, 1000 * 60);
  };

  getLocalTiles = () => {
    let dash_tiles = localStorage.getItem("dash_tiles");
    if (dash_tiles) {
      this.setState({
        tiles: JSON.parse(dash_tiles),
      });
    }
  };

  componentDidMount = () => {
    this.autoRefresh();
    this.getLocalTiles();
  };

  calculateTrend = (total, average) => {
    if (!average) {
      return [false, {}];
    }
    let avg = (total / average) * 100 - 100;
    if (avg < 0) {
      return [
        true,
        {
          color: "green",
          text: `${Math.abs(avg).toFixed(2)}% Lower`,
          icon: <MdArrowDownward />,
        },
      ];
    }

    return [
      true,
      {
        color: "red",
        text: `${Math.abs(avg).toFixed(2)}% Higher`,
        icon: <MdArrowUpward />,
      },
    ];
  };

  fetchTilesData = async () => {
    let display = this.state.tiles,
      trend = display?.filter((tile) => tiles[tile].trend);
    await fetchTiles(display, trend)
      .then(({ data }) => {
        this.setState({
          data: data,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  renderCard = ({
    title,
    icon,
    color,
    data,
    footerLeft,
    footerRight,
    tile_name,
  }) => {
    const { classes } = this.props;
    return (
      <Col
        xs={24}
        lg={6}
        md={12}
        data-json={`${JSON.stringify({ tile_name })}`}
        style={{ paddingRight: "15px" }}
      >
        <Card style={{ marginBottom: 5 }}>
          <CardHeader color={color} stats icon>
            <CardIcon color={color}>{icon}</CardIcon>
            <p className={classes.cardCategory}>{title}</p>
            <h2 className={classes.cardTitle} style={{ fontSize: "32px" }}>
              {data}
            </h2>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>{footerLeft}</div>
            {footerRight}
          </CardFooter>
        </Card>
      </Col>
    );
  };

  render_tile = (name, title, color, icon, trend, tile_name) => {
    let json = this.state.data[name] || {};
    let data = (json && json.count) || 0;
    let footerLeft = json && this.buildPeriod(json.since);
    let footerRight = "";
    if (trend && json) {
      let values = this.calculateTrend(json.count, json.average);
      footerRight = this.buildTrend(values);
    }
    return this.renderCard({
      title,
      icon,
      data,
      color,
      footerLeft,
      footerRight,
      tile_name,
    });
  };

  render_total_vehicles = (title, color, icon, trend, tile_name) => {
    const { total_vehicles } = this.state.data || {};
    let data = (total_vehicles && total_vehicles.count) || 0;
    let footerLeft = (
      <React.Fragment>
        <ColIcons icon_path={commute} />
        &nbsp; Since{" "}
        {moment(total_vehicles && total_vehicles.since).format(
          "DD-MMM-YYYY"
        )}{" "}
      </React.Fragment>
    );
    return this.renderCard({
      title,
      icon,
      color,
      data,
      footerLeft,
      tile_name,
    });
  };

  render_today_vehicles_in = (title, color, icon, trend, tile_name) => {
    const { today_vehicles_in } = this.state.data || {};
    let data = (today_vehicles_in && today_vehicles_in.count) || 0;
    let footerLeft = (
      <React.Fragment>
        <MdToday icon_path={commute} />
        Today
      </React.Fragment>
    );
    return this.renderCard({
      title,
      icon,
      color,
      data,
      footerLeft,
      tile_name,
    });
  };

  render_today_vehicles_out = (title, color, icon, trend, tile_name) => {
    const { today_vehicles_out } = this.state.data || {};
    let data = (today_vehicles_out && today_vehicles_out.count) || 0;
    let footerLeft = (
      <React.Fragment>
        <MdToday icon_path={commute} />
        Today
      </React.Fragment>
    );
    return this.renderCard({
      title,
      icon,
      color,
      data,
      footerLeft,
      tile_name,
    });
  };

  render_total_vehicle_entries = (title, color, icon, trend, tile_name) => {
    const { total_vehicle_entries } = this.state.data || {};
    let data = (total_vehicle_entries && total_vehicle_entries.count) || 0;
    let footerLeft = (
      <React.Fragment>
        <ColIcons icon_path={commute} />
        &nbsp; Since{" "}
        {moment(total_vehicle_entries && total_vehicle_entries.since).format(
          "DD-MMM-YYYY"
        )}{" "}
      </React.Fragment>
    );
    return this.renderCard({
      title,
      icon,
      color,
      data,
      footerLeft,
      tile_name,
    });
  };

  render_total_visitors = (title, color, icon, trend, tile_name) => {
    const { total_visitors } = this.state.data || {};
    let data = (total_visitors && total_visitors.count) || 0;
    let footerLeft = (
      <React.Fragment>
        <IoMdPeople />
        since{" "}
        {moment(total_visitors && total_visitors.since).format(
          "DD-MMM-YYYY"
        )}{" "}
      </React.Fragment>
    );
    return this.renderCard({
      title,
      icon,
      color,
      data,
      footerLeft,
      tile_name,
    });
  };

  render_working_camera = (title, color, icon, trend, tile_name) => {
    const { working_camera } = this.state.data || {};
    let data =
      (working_camera &&
        `${working_camera.working} / ${working_camera.total}`) ||
      0;
    let footerLeft = "";
    if (working_camera) {
      if (working_camera.working === working_camera.total) {
        footerLeft = (
          <React.Fragment>
            <MdThumbUp style={{ color: "#03b10d" }} /> All Cameras are active
          </React.Fragment>
        );
      } else {
        footerLeft = (
          <React.Fragment>
            <MdWarning style={{ color: "#ff2323" }} />
            Some Cameras are inactive
          </React.Fragment>
        );
      }
    }
    return this.renderCard({
      title,
      icon,
      color,
      data,
      footerLeft,
      tile_name,
    });
  };

  buildPeriod = (date) => {
    return (
      <React.Fragment>
        <MdUpdate />
        Last {moment().diff(moment(date), "hours")} hours
      </React.Fragment>
    );
  };

  buildTrend = (object) => {
    if (!object[0]) {
      return "";
    }

    const { classes } = this.props;
    return (
      <div className={classes.stats} style={{ color: object[1].color }}>
        {object[1].icon}
        {object[1].text}
      </div>
    );
  };

  handleRenderer = (tile_name) => {
    let tile = tiles[tile_name],
      function_name = "render_" + tile_name;

    if (typeof this[function_name] === "function") {
      return this[function_name](
        tile.title,
        tile.color,
        tile.icon,
        tile.trend,
        tile_name
      );
    } else {
      return this.render_tile(
        tile_name,
        tile.title,
        tile.color,
        tile.icon,
        tile.trend,
        tile_name
      );
    }
  };

  render() {
    const { tiles } = this.state;
    return (
      <Row style={{ margin: 0 }}>
        {tiles.map((name) => this.handleRenderer(name))}
      </Row>
    );
  }
}

Tiles.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Tiles);
