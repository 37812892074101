import React, { Component } from "react";
import { createOutsider, updateOutsider, deleteOutsider } from "api/outsiders";
import { createVehicle } from "api/vehicles";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
import Form from "./Form";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      name: "",
      phone: "",
      vehicles: [],
    };
  }

  handleChange = (event, index, id) => {
    if (index !== undefined) {
      let vehicles = this.state.vehicles;
      vehicles[index][id] = event.target.value;
      this.setState({ vehicles });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  requestParams = () => {
    const { name, phone, vehicles } = this.state;

    let vehicle_ids = vehicles.map((vehicle) => vehicle.value);
    return {
      visitors: {
        name,
        phone,
        vehicle_ids,
      },
    };
  };

  onSubmit = () => {
    const { id, props } = this;
    props.submitClick();
    const method = !id ? createOutsider : updateOutsider;
    method(this.requestParams(), id)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
        props.submitClick();
      })
      .catch((error) => {
        props.submitClick();
        handleResponse(error);
      });
  };

  onCreate = (value) => {
    let vehicles = this.state.vehicles ? [...this.state.vehicles] : [];
    createVehicle({ vehicles: { number_plate: value } })
      .then(({ data }) => {
        vehicles.push({
          value: data.id,
          label: data.number_plate,
        });
        this.setState({ vehicles });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    if (this.props.data) {
      const { id, name, phone, vehicles } = this.props.data;

      this.id = id;
      this.setState({
        name,
        phone,
        vehicles:
          vehicles &&
          vehicles.map((vehicle) => {
            return {
              value: vehicle.id,
              label: vehicle.number_plate,
            };
          }),
      });
    }
  };

  onDelete = () => {
    this.setState({ open: true });
  };

  confirmDelete = () => {
    const { id } = this;

    deleteOutsider(id)
      .then(() => {
        this.setState({ open: false });
        success("Deleted Successfully..!!");
        this.props.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  cancelDelete = () => {
    this.setState({ open: false });
  };

  render() {
    const { handleChange, onCreate } = this;
    const { dateFilter } = this.props;

    const { name, phone, vehicles } = this.state;
    const OutsiderProps = {
      dateFilter,
      name,
      phone,
      vehicles,
      handleChange,
      handleCreate: onCreate,
    };
    return <Form {...OutsiderProps} />;
  }
}
export default DialogForm("Outsiders")(CRUD);
