import React from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { Row, Col } from "antd";
import RadioButton from "components/inputs/radioButton.jsx";
import { purpose } from "config";

export default function Form(props) {
  const {
    id,
    data,
    handleChange,
    handleChangeSelect,
    tabChange,
  } = props;
  const deviceStatus = ["active", "inactive"];
  const {
    name,
    mac_address,
    status,
    type,
    camera_type,
    timeout,
  } = data;
  const timeOutStyle = {
    padding: "10px 20px",
    marginTop: "12px",
  };
  return (
    <React.Fragment>
      <Row style={{ padding: 10 }}>
        <Col xs={24} sm={12}>
          <Row>
            <Col xs={24} sm={24} style={{ padding: "10px 20px" }}>
              <SimpleTextInput
                labelText="Name"
                id="name"
                value={name}
                onChange={handleChange}
                disabled={id}
                required
              />
            </Col>
            <Col xs={24} sm={24} style={{ padding: "10px 20px" }}>
              <SimpleTextInput
                labelText="MAC Address"
                id="mac_address"
                value={mac_address}
                onChange={handleChange}
                required
                disabled={id}
              />
            </Col>
            <Col xs={24} sm={24} style={{ padding: "10px 17px" }}>
              <RadioButton
                labelText="Status"
                value={status || "active"}
                data={deviceStatus}
                onChange={handleChangeSelect}
                name="status"
                color={true}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={12}>
          <Row>
            {!id && type && (
              <Col
                xs={24}
                sm={24}
                style={{ padding: "7px 20px", width: "290px" }}
              >
                <RadioButton
                  labelText="Purpose"
                  value={type || "LnprCamera"}
                  data={purpose}
                  onChange={tabChange}
                  name="type"
                />
              </Col>
            )}
            <Col
              xs={24}
              sm={24}
              style={{ padding: "10px 20px", marginTop: "8px" }}
            >
              <FormControl fullWidth>
                {type && (
                  <InputLabel htmlFor="age-simple">Camera Type</InputLabel>
                )}
                {type === "LnprCamera" && (
                  <Select
                    style={{ width: "100%", height: "84%" }}
                    name="camera_type"
                    value={camera_type}
                    onChange={handleChangeSelect}
                    required
                    disabled={id}
                  >
                    <MenuItem value={"visitor_entry_cam"}>Entry Cam</MenuItem>
                    <MenuItem value={"visitor_exit_cam"}>Exit Cam</MenuItem>
                    <MenuItem value={"internal_cam"}>Internal Cam</MenuItem>
                  </Select>
                )}
                {type === "AlertCamera" && (
                  <Select
                    style={{ width: "100%", height: "84%" }}
                    name="camera_type"
                    value={camera_type}
                    onChange={handleChangeSelect}
                    required
                    disabled={id}
                  >
                    <MenuItem selected value={"alert_cam"}>
                      Alert Cam
                    </MenuItem>
                  </Select>
                )}
              </FormControl>
            </Col>

            <Col
              xs={24}
              sm={24}
              style={
                !id ? timeOutStyle : { padding: "10px 20px", marginTop: "9px" }
              }
            >
              <SimpleTextInput
                labelText="TimeOut (in minutes)"
                id="timeout"
                value={timeout}
                onChange={handleChange}
                required
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
}
