import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  setCookie,
  popCookie,
  presentationAccess,
  refreshAPI,
  debounce,
} from "helpers";
import { columns } from "data/alerts";
import { Col, Row } from "antd";
import AppBar from "components/AppBar";
import {
  fetchListingData,
  fetchAlertTypes,
  triggerReportDownload,
} from "api/alerts";
import PaginationTable from "components/paginationTables/withRefreshDownload";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import CRUDAlerts from "components/form/alerts/alertDetails";
import AlertTiles from "./AlertTiles";
import CRUDMultiAlerts from "components/form/alerts/multiResolve";
import moment from "moment";

class Alerts extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = {};
    this.refreshAPI = refreshAPI.bind(this);
    this.state = {
      data: [],
      alert_type: [],
      default_alert_type: [],
      total_entries: 0,
      cardOpen: false,
      rowData: undefined,
      fromTime: "",
      toTime: "",
      status: ["Raised"],
      page: 1,
      skeleton: true,
      tileData: [],
      loader: false,
      selectedRowKeys: [],
      multiResolveFormOpen: false,
    };
  }

  initialRequestParams = () => {
    return {
      search: "",
      perPage: 25,
      page: 1,
      status: ["Raised"],
      alert_type: this.props.alertProps.type,
      alert_group: this.props.alertProps.group,
      not_alert_group: this.props.alertProps.not_alert_group,
      not_alert_type: this.props.alertProps.not_alert_type,
    };
  };

  alertTypes = () => {
    const alertTypes = [];
    fetchAlertTypes()
      .then(({ data }) => {
        const {
          type,
          group,
          not_alert_group,
          not_alert_type,
        } = this.props.alertProps;
        data.forEach((at) => {
          if (type?.length && type.includes(at.alias_name)) {
            if (at.status === "active") {
              alertTypes.push({
                label: at.name,
                value: at.alias_name,
              });
            }
          }
          if (group?.length && group.includes(at.alert_group.name)) {
            if (at.status === "active") {
              alertTypes.push({
                label: at.name,
                value: at.alias_name,
              });
            }
          }
          if (
            not_alert_group?.length &&
            !not_alert_group.includes(at.alert_group.name)
          ) {
            if (
              not_alert_type?.length &&
              !not_alert_type.includes(at.alias_name)
            ) {
              if (at.status === "active") {
                alertTypes.push({
                  label: at.name,
                  value: at.alias_name,
                });
              }
            }
          }
        });
        this.setState({ default_alert_type: alertTypes });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount = async () => {
    let scrollY = 0;
    let prevStateValues = popCookie("prevStateValue");
    let usePrevStateValues = popCookie("usePrevStateValue");

    if (usePrevStateValues && prevStateValues) {
      let data = JSON.parse(prevStateValues);
      this.requestParams = data.requestParams;
      scrollY = data.scrollY;
    } else {
      this.requestParams = await this.initialRequestParams();
    }

    this.fetchTableData();
    setTimeout(function() {
      window.scrollTo({ top: scrollY });
    }, 1000);
    this.alertTypes();
    this.refreshAPI(this.fetchTableData, 30000, "alerts", 60000);
  };

  componentWillUnmount = () => {
    let prevStateValues = JSON.stringify({
      scrollY: window.scrollY,
      requestParams: this.requestParams,
    });
    setCookie("prevStateValue", prevStateValues);
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  };

  filterChange = ({
    fromTime,
    toTime,
    alert_type,
    status,
    page,
    loader,
    date,
  }) => {
    const from_time = new Date();
    from_time.setUTCHours(6, 30, 0, 999);
    const currentFromTime = moment
      .utc(from_time.setDate(from_time.getDate() - 1))
      .format();

    const to_time = new Date();
    to_time.setUTCHours(18, 29, 59, 999);
    const currentToTime = moment.utc(to_time).format();

    let alert_type_values = [];
    if (alert_type?.length) {
      alert_type_values = alert_type.map((value) => value.value);
    } else if (this.props.alertProps.type?.length) {
      alert_type_values = this.props.alertProps?.type;
    }

    if (!date) {
      this.requestParams.fromTime =
        fromTime !== "" ? moment.utc(fromTime).format() : currentFromTime;
      this.requestParams.toTime =
        toTime !== "" ? moment.utc(toTime).format() : currentToTime;
    } else {
      this.requestParams.fromTime = "";
      this.requestParams.toTime = "";
    }

    this.requestParams.status = status && status;
    this.requestParams.alert_type = alert_type_values;
    this.requestParams.page = 1;
    this.setState({ fromTime, toTime, alert_type, status, page, loader });
    this.fetchTableData();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  fetchTableData = async () => {
    await fetchListingData(this.requestParams)
      .then(({ data, total_entries, aggregation }) => {
        let groups = data
          .map((x) => x.alert_group_name)
          .filter((v, i, a) => a.indexOf(v) === i);
        let buckets =
          aggregation.alert_group_name?.alert_group_name?.buckets || [];

        let object = {};
        buckets.map((x) => {
          object[x.key] = x.doc_count;
          return object;
        });

        let result = [];

        Object.keys(object).forEach((x) => {
          if (groups.includes(x)) {
            result.push({ [x]: object[x] });
          }
        });

        let alert_types =
          aggregation.alert_group_name.buckets ||
          aggregation.alert_group_name.alert_group_name.buckets ||
          [];

        this.setState({
          tileData: result,
          data: data,
          total_entries: total_entries,
          alert_types,
          skeleton: false,
          loader: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ selectedRowKeys: [] });
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    let sort_by = "weight";
    switch (sorter.order) {
      case "ascend":
        sort_by = "from_time_asc";
        break;
      case "descend":
        sort_by = "from_time_desc";
        break;
      default:
        break;
    }
    this.requestParams.sort_by = sort_by;
    this.fetchTableData();
  };

  handleSubmitSuccess = () => {
    this.setState({ selectedRowKeys: [] });
    this.timer = setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  handleClose = () => {
    this.setState({ cardOpen: false, multiResolveFormOpen: false });
  };

  generateReport = async () => {
    await triggerReportDownload(this.requestParams, this.state.total_entries)
      .then((data) => {
        success(data.message);
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  triggerResolve = () => {
    this.setState({ multiResolveFormOpen: true });
  };

  multiResolveCall = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  ResolveButton = () => {
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const {
      data,
      cardOpen,
      rowData,
      alert_type,
      fromTime,
      toTime,
      status,
      skeleton,
      default_alert_type,
      loader,
      selectedRowKeys,
      multiResolveFormOpen,
    } = this.state;
    const {
      onChange,
      filterChange,
      fetchTableData,
      generateReport,
      handleClose,
      multiResolveCall,
      triggerResolve,
      ResolveButton,
    } = this;

    let screenUser = presentationAccess();

    const tableProps = {
      tabColor: "danger",
      title: this.props.alertProps.title,
      subTitle: this.props.alertProps.subtitle,
      columns,
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      triggerRefresh: fetchTableData,
      generateReport: generateReport,
      onRowClick: (data) => {
        this.setState({
          cardOpen: true,
          rowData: data,
        });
      },
      downloadArrow: true,
      multiResolve: selectedRowKeys.length > 0 ? true : false,
      multiResolveCall,
      screenUser,
      skeleton,
      refershloader: true,
      loader,
      rowSelection: true,
      triggerResolve,
      selectedRowKeys,
    };

    const appBarProps = {
      search: this.requestParams.search,
      dateFilter: false,
      // searchOpen: true,
      onChange,
      filterChange,
      date: true,
      filterObj: {
        fromTime,
        toTime,
        alert_type,
        default_alert_type,
        status,
      },
    };
    const multiform = {
      selectedRowKeys,
      handleClose,
      ResolveButton,
    };

    const CRUDProps = { handleClose, rowData, history: this.props.history };
    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        {this.state.tileData.length > 0 && (
          <Row>
            <AlertTiles alertType={this.state.tileData} />
          </Row>
        )}
        <Col>
          <PaginationTable {...tableProps} />
        </Col>
        {cardOpen && (
          <CRUDAlerts
            {...CRUDProps}
            handleSubmitSuccess={this.handleSubmitSuccess}
          />
        )}
        {multiResolveFormOpen && (
          <CRUDMultiAlerts
            {...multiform}
            handleSubmitSuccess={this.handleSubmitSuccess}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Alerts);
