import React, { Component } from "react";
import { Table, Button, Drawer, Typography } from "antd";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import ViewJSON from "./ViewJSON";
import {
  handleResponse,
  success,
  warn,
} from "components/notifications/handleResponse";
import {
  fetchKioskWorkflows,
  fetchVizitinWorkflows,
  createKioskWorkflow,
  createVizitinWorkflow,
  updateKioskWorkflow,
  updateVizitinWorkflow,
} from "api/workflow";

import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";
import UploadFile from "views/UploadFile";

class VersionConfig extends Component {
  constructor(props) {
    super(props);
    this.handleChild = React.createRef();
    this.state = {
      data: { name: "", version: "" },
      selected_id: props.deviceData?.workflow_id,
      visibleTable: true,
      visible: false,
      kioskWorkFlow: [],
      kioskData: [],
      vizitinData: [],
      current_workflow_id: props.deviceData?.workflow_id,
    };
  }

  UNSAFE_componentWillReceiveProps = (props) => {
    this.setState({ selected_id: props.deviceData?.workflow_id });
  };

  componentDidMount() {
    const { device, reCall } = this.props;
    const { fetchKioskData, fetchVizitinData } = reCall;
    switch (device) {
      case "kiosks":
        this.fetchKioskWorkflowDetails();
        fetchKioskData();
        break;
      case "vizitin":
        this.fetchVizitinWorkflowDetails();
        fetchVizitinData();
        break;
      default:
        break;
    }
  }

  handleChange = (e) => {
    let { data } = this.state;
    let { name, value } = e.target;
    data[name] = value;
    this.setState({ data });
  };

  handleSubmitSuccess = () => {
    const { device } = this.props;
    this.onClose();
    this.timer = setTimeout(() => {
      switch (device) {
        case "kiosks":
          this.fetchKioskWorkflowDetails();
          break;
        case "vizitin":
          this.fetchVizitinWorkflowDetails();
          break;
        default:
          break;
      }
    }, 1000);
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  fetchKioskWorkflowDetails = () => {
    const { id } = this.props.deviceData || {};
    fetchKioskWorkflows(id)
      .then(({ data }) => {
        this.setState({ kioskData: data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchVizitinWorkflowDetails = () => {
    const { id } = this.props.deviceData || {};
    fetchVizitinWorkflows(id)
      .then(({ data }) => {
        this.setState({ vizitinData: data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onSubmit = () => {
    const { deviceData, device } = this.props;
    const fileData = this.handleChild.current.requestParams();
    let id = deviceData.id;

    if (!fileData) {
      warn("Please Select the file");
      return;
    }
    const {
      data: { name, version },
    } = this.state;
    const form = new FormData();
    form.append("file", fileData);
    form.append("workflow[name]", name);
    form.append("workflow[version]", version);
    let action =
      device === "kiosks" ? createKioskWorkflow : createVizitinWorkflow;

    action(id, form)
      .then(({ data }) => {
        this.setState({ selected_id: data.id, current_workflow_id: data.id });
        success("Workflow uploaded..!!!");
        this.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  update = () => {
    const { deviceData, device } = this.props;
    let id = deviceData.id;
    let workflow_id = this.state.selected_id;
    let action =
      device === "kiosks" ? updateKioskWorkflow : updateVizitinWorkflow;
    action(id, workflow_id)
      .then(({ data }) => {
        this.setState({ selected_id: data.id, current_workflow_id: data.id });
        success("Workflow updated..!!!");
        this.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const { title, device } = this.props;
    const { handleChange, handleChild, update } = this;
    const columns = [
      { title: "Name", dataIndex: "name" },
      { title: "Version", dataIndex: "version", width: "20%" },
      {
        title: "Operation",
        align: "center",
        width: "30%",
        render: (_, row) => {
          return (
            <ViewJSON
              data={row.content}
              title={row.name}
              filename={`${title}_${row.name}_${row.version}`}
            />
          );
        },
      },
    ];

    const {
      visible,
      kioskData,
      vizitinData,
      selected_id,
      current_workflow_id,
    } = this.state;
    let disabled = current_workflow_id === selected_id;
    let tableData = device === "kiosks" ? kioskData : vizitinData;
    return (
      <React.Fragment>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Typography.Title level={3} style={{ flexGrow: 1 }}>
            {title}
          </Typography.Title>
          <div style={{ margin: "0px 10px 10px 0px" }}>
            <Button
              onClick={() => {
                this.setState({ visible: true });
              }}
            >
              Add
            </Button>
          </div>
          <div style={{ marginBottom: 10 }}>
            <Button onClick={update} disabled={disabled}>
              Make Current
            </Button>
          </div>
        </div>
        <Paper
          style={{
            width: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Table
            style={{ width: "100%", overflowY: "auto" }}
            bordered
            rowSelection={{
              selectedRowKeys: [selected_id || this.props.deviceData?.wor],
              type: "radio",
              onSelect: (row) => this.setState({ selected_id: row.id }),
            }}
            rowKey={(row) => row.id}
            scroll={{ y: "63vh" }}
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        </Paper>
        {visible && (
          <Drawer
            title={`Add Workflow`}
            width={450}
            closable={true}
            visible={visible}
            onClose={() => this.setState({ visible: false })}
          >
            <Row>
              <Col xs={8} sm={8} md={8} style={{ padding: "10px" }}>
                <SimpleTextInput
                  labelText="Name"
                  name="name"
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col xs={8} sm={8} md={8} style={{ padding: "10px" }}>
                <SimpleTextInput
                  labelText="Version"
                  name="version"
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} style={{ padding: "10px" }}>
                <div className="dropbox">
                  <UploadFile
                    ref={handleChild}
                    accept={".json"}
                    {...{
                      title: "",
                      uploadButton: false,
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div
              style={{
                justifyContent: "end",
                display: "flex",
                marginRight: "15px",
                padding: "10px",
              }}
            >
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => {
                  this.setState({ visible: false });
                }}
              >
                Cancel
              </Button>
              <Button onClick={this.onSubmit} type="primary">
                Submit
              </Button>
            </div>
          </Drawer>
        )}
      </React.Fragment>
    );
  }
}

VersionConfig.propTypes = {
  getURL: PropTypes.string.isRequired,
  postURL: PropTypes.string.isRequired,
  config_type: PropTypes.string.isRequired,
  deviceData: PropTypes.string.isRequired,
};

export default VersionConfig;
