import React, { Component } from "react";
import Form from "./Form";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
import { updateBlackLists, createBlackLists } from "api/blackList";
import { createVehicle } from "api/vehicles";
import { createOutsider } from "api/outsiders";
import PropTypes from "prop-types";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      data: {
        entity_type: "Vehicle",
        status: "active",
        remarks: "",
        vehicles: null,
        visitors: null,
      },
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const { data } = this.state;
    this.setState({ data: { ...data, [name]: value } });
  };

  handleCreateVehicles = (value) => {
    createVehicle({ vehicles: { number_plate: value } }).then(({ data }) => {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          vehicles: { label: data.number_plate, value: data.id },
          entity_type: "Vehicle",
        },
      }));
    });
  };

  handleCreateVisitors = (value) => {
    createOutsider({ visitors: { phone: value } }).then(({ data }) => {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          visitors: { label: data.phone, value: data.id },
          entity_type: "Visitor",
        },
      }));
    });
  };

  requestParams = () => {
    const { vehicles, visitors, ...restData } = this.state.data;
    let entity_id =
      restData.entity_type === "Vehicle" ? vehicles?.value : visitors?.value;

    return {
      blacklists: {
        ...restData,
        entity_id,
      },
    };
  };

  componentDidMount = () => {
    if (Object.keys(this.props.data).length === 0) {
      return;
    }

    const { id, entity, entity_type, status, remarks } = this.props.data;
    let vehicles, visitors;
    if (entity_type === "Vehicle") {
      vehicles = { label: entity.number_plate, value: entity.id };
    } else if (entity_type === "Visitor") {
      visitors = { label: entity.phone, value: entity.id };
    }
    this.id = id;
    const tempData = { entity_type, status, remarks, vehicles, visitors };

    this.setState({ data: tempData });
  };

  onSubmit = () => {
    const {
      id,
      requestParams,
      props: { submitClick, handleSubmitSuccess },
    } = this;
    const method = id ? updateBlackLists : createBlackLists;

    submitClick();
    method(requestParams(), id)
      .then((data) => {
        success(data.message);
        handleSubmitSuccess();
        submitClick();
      })
      .catch((error) => {
        submitClick();
        handleResponse(error);
      });
  };

  render() {
    const {
      state: { data },
      handleChange,
      handleCreateVehicles,
      handleCreateVisitors,
    } = this;

    return (
      <Form
        {...{
          ...data,
          handleChange,
          handleCreateVehicles,
          handleCreateVisitors,
        }}
      />
    );
  }
}

CRUD.defaultProps = {
  data: {},
};

CRUD.propTypes = {
  data: PropTypes.object,
  submitClick: PropTypes.func,
  handleSubmitSuccess: PropTypes.func,
};

export default DialogForm("BlackLists")(CRUD);
