import makeRequest from ".";

function generateParams(params = {}) {
  let listStatus = "";
  if (params.status) {
    listStatus = params.status.map((value) => `&status[]=${value}`).join("");
  }

  return `query=${params.search}${listStatus}&page=${params.page ||
    1}&per_page=${params.perPage || 25}`;
}

export function fetchListingData(requestParams) {
  return makeRequest({
    uri: `/api/v1/sm_vehicles?${generateParams(requestParams)}`,
  });
}

export function createSmVehicle(requestBody) {
  return makeRequest({
    uri: "/api/v1/sm_vehicles",
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateSmVehicle(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/sm_vehicles/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}
