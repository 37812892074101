import React, { Component } from "react";
import MailPopup from "./mailPopup";
import SmsPopup from "./smsPopup";
import Dialog from "@material-ui/core/Dialog";
import {
  Card,
  CardBody,
} from "components/material-dashboard-react/components/index";
import { Row, Col } from "antd";
import { showAlerts } from "api/sentAlerts";
import { handleResponse } from "components/notifications/handleResponse";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "antd";

const style = () => ({
  close: {
    cursor: "pointer",
    color: "black",
    background: "#e1e1e1",
    border: "1px solid #e1e1e1",
    "&:hover": {
      background: "#f73232",
      color: "white",
    },
  },
});
class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      data: [],
    };
  }

  fetchData = () => {
    showAlerts(this.props.data?.id)
      .then(({ data }) => this.setState({ data }))
      .catch((error) => {
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    this.fetchData();
  };

  render() {
    const { onCancel } = this.props;
    const {
      id,
      from,
      subject,
      recipient,
      content,
      medium,
      status,
      msg_id,
    } = this.state.data;
    const formProps = { id, from, subject, recipient, content, status, msg_id };
    const smsProps = { id, from, recipient, content, status, msg_id };
    return (
      <React.Fragment>
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: { overflow: "visible", minwidth: "70%", maxWidth: "100%" },
          }}
        >
          <Row xs={24} sm={24} md={24}>
            <Card
              style={{
                margin: 0,
                width: "100%",
                display: "flex",
                height: "70%",
              }}
            >
              <CardBody>
                <Row>
                  <Col xs={24} sm={24} md={24}>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: " 0px 0px 15px",
                      }}
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        icon="close"
                        size="default"
                        onClick={onCancel}
                      />
                    </span>
                    {medium === "Mail" ? (
                      <MailPopup {...formProps} />
                    ) : (
                      <SmsPopup {...smsProps} />
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default withStyles(style)(CRUD);
