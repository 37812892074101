import React, { Component } from "react";
import moment from "moment";
import AppBar from "components/AppBar";
import { sentAlertsColumn } from "data/sentAlerts";
import PaginationTable from "components/paginationTables/withRefreshDownload";
import { fetchListingData } from "api/sentAlerts";
import { Row } from "antd";
import { withRouter } from "react-router-dom";
import { handleResponse } from "components/notifications/handleResponse";
import { debounce, setCookie, popCookie } from "helpers";
import CRUDSmsalerts from "components/form/smsalerts/CRUD";

class SentAlerts extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      skeleton: true,
      formOpen: false,
      rowData: undefined,
      status: [],
      recipient: [],
    };
  }

  initialRequestParams = () => {
    return {
      fromTime: moment.utc(moment().startOf("week")).format(),
      toTime: moment.utc(moment().endOf("week")).format(),
      selectedStatus: [],
      selectedRecipient: [],
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount = () => {
    this.autoRefresh();
    this.fetchTableData();
  };

  componentDidMount = () => {
    let scrollY = 0;
    let prevStateValues = popCookie("prevStateValues");
    let usePrevStateValues = popCookie("usePrevStateValues");

    if (usePrevStateValues && prevStateValues) {
      let data = JSON.parse(prevStateValues);
      if (data.currentUrl === this.currentUrl) {
        this.requestParams = data.requestParams;
        scrollY = data.scrollY;
        this.previousLoad = true;
      } else {
        this.requestParams = this.initialRequestParams();
      }
    } else {
      this.requestParams = this.initialRequestParams();
    }

    this.autoRefresh();

    setTimeout(function() {
      window.scrollTo({ top: scrollY });
    }, 1000);
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    let prevStateValues = JSON.stringify({
      scrollY: window.scrollY,
      requestParams: this.requestParams,
      currentUrl: this.currentUrl,
    });
    setCookie("prevStateValues", prevStateValues);
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.fetchTableData();
    this.timer = setTimeout(this.autoRefresh, 10000);
  };

  onDateChange = (from_time, to_time) => {
    this.requestParams.fromTime = from_time;
    this.requestParams.toTime = to_time;
    this.fetchTableData();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 10000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  fetchTableData = () => {
    fetchListingData(this.requestParams)
      .then(({ data, total_entries, aggregation }) => {
        let recipient = aggregation["recipient.full_name"][
          "recipient.full_name"
        ]["buckets"].map((value) => value.key);
        let status = aggregation["status"]["status"]["buckets"].map(
          (value) => value.key
        );

        setTimeout(() => {
          this.setState({
            data: data,
            total_entries: total_entries,
            recipient,
            status,
            skeleton: false,
          });
        }, 500);
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.selectedStatus = filters.status;
    this.requestParams.selectedRecipient = filters["recipient"] || [];

    this.fetchTableData();
  };

  onClose = () => {
    this.setState({ formOpen: false });
  };

  inviteeDetails = (row) => {
    let invitee_id = row.id;
    let invitee_phone = row.phone;

    if (invitee_id && invitee_phone && row.status === "visited") {
      setCookie("visitor_entry_id", invitee_id);
      this.props.history.push({
        pathname: `/outsiders/${invitee_phone}`,
      });
    }
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    this.timer = setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
    });
  };

  onRowClick = (row) => {
    this.setState({ formOpen: true, rowData: row });
  };

  render() {
    const { data, skeleton, formOpen, status, recipient, rowData } = this.state;
    const {
      onChange,
      onDateChange,
      fetchTableData,
      handleClose,
      onRowClick,
      handleSubmitSuccess,
    } = this;
    const {
      selectedStatus,
      selectedRecipient,
      selectedMedium,
    } = this.requestParams;
    const tableProps = {
      columns: sentAlertsColumn(
        status,
        recipient,
        selectedStatus,
        selectedRecipient,
        selectedMedium
      ),
      tabColor: "info",
      title: "Sent Alerts",
      subTitle: "SMS, FCM, Mail WhatsApp",
      onRowClick: onRowClick,
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      triggerRefresh: fetchTableData,
      skeleton,
    };

    const CRUDProps = {
      data: rowData,
      onCancel: handleClose,
      handleSubmitSuccess,
      tabColor: "danger",
    };
    const appBarProps = {
      fromTime: moment(this.requestParams.fromTime),
      toTime: moment(this.requestParams.toTime),
      search: this.requestParams.search,
      dateFilter: true,
      onChange,
      onDateChange,
    };
    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        {formOpen && <CRUDSmsalerts {...CRUDProps} />}
      </React.Fragment>
    );
  }
}

export default withRouter(SentAlerts);
