import React, { Component } from "react";
import { Row, Col, Button, Tabs } from "antd";
import { formatTime } from "helpers";
import { apiHost } from "config";
import { AlertResolveOptions, SpecialVehicleResolve } from "config";
import SimpleTextInput from "components/inputs/simpleTextInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { AlertInfo } from "./alertInfo";
import { IoMdOpen } from "react-icons/io";
import { presence } from "helpers";

const styles = {
  container: {
    maxHeight: "430px",
    overflowX: "auto",
    minWidth: "600px",
  },
  buttonStyle: {
    color: "white",
    backgroundColor: "#105905",
    borderRadius: "5px",
    fontWeight: "bolder",
    height: "40px",
    fontSize: "16px",
  },

  ColStyle: {
    padding: "0px 0px 20px 10px",
    textAlign: "center",
    zIndex: 0,
  },
  description: {
    textAlign: "left",
    fontSize: "20px",
    width: "100%",
    color: "#393A3B",
  },
  resolveLine: {
    fontSize: "16px",
    textAlign: "left",
    padding: "0 .5rem",
    marginTop: "1rem",
  },
  resolveReason: {
    fontSize: "16px",
    padding: "0 .5rem",
    textAlign: "left",
    fontWeight: "bolder",
    marginTop: "1rem",
  },
  detailsTab: {
    fontSize: "17px",
    fontWeight: "bolder",
    padding: "0",
  },
  resolveTab: {
    fontSize: "17px",
    fontWeight: "bolder",
    padding: "0",
  },
  resolvedStyle: {
    padding: "1rem",
    marginLeft: "1rem",
    width: "100%",
  },
};

const alertStyle = {
  detailStyle: {
    padding: ".3rem",
    color: "white",
    borderRadius: "0px",
    marginBottom: "0",
    backgroundColor: "#008080",
    width: "150px",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
  },
  entryInfo: {
    textAlign: "left",
    minWidth: "35%",
    color: "grey",
    fontSize: "16px",
  },
  numberPlateIcon: {
    fontSize: "16px",
    marginLeft: ".5rem",
    cursor: "pointer",
    color: "rgb(33, 150, 243)",
  },
  phoneNoIcon: {
    fontSize: "16px",
    marginLeft: ".5rem",
    cursor: "pointer",
    color: "rgb(33, 150, 243)",
  },
  borderStyle: {
    borderBottom: "1px solid gray",
    marginBottom: "1%",
    marginRight: "1rem",
  },
};
class AlertUpdate extends Component {
  constructor(props) {
    super(props);
    this.vehicleOption = this.props.type === "special_vehicle.entry" ? SpecialVehicleResolve : AlertResolveOptions
    this.state = {
      resolve_msg: this.vehicleOption[0],
      remark_msg: "",
      vehicleDetails: this.props.entity?.vehicle,
    };
  }

  handleChange = (e) => {
    this.setState({ remark_msg: e.target.value });
  };

  handleChangeSelect = (e) => {
    this.setState({ resolve_msg: e.target.value, remark_msg: "" });
  };

  handleSubmit = () => {
    const { resolve_msg, remark_msg } = this.state;
    const remark_message =
      remark_msg.length === 0 ? resolve_msg : `${resolve_msg} : ${remark_msg}`;
    this.props.handleResolved(remark_message);
  };
  render() {
    let { props } = this;
    let des_display = props.status === "Raised" ? "block" : "flex";
    let des_content = props.status === "Raised" ? "start" : "center";
    const { remark_msg, resolve_msg, vehicleDetails } = this.state;
    const {
      description,
      numberPlateOnclick,
      visitorOnclick,
      changeColor,
    } = this.props;

    const { handleChangeSelect, handleChange, handleSubmit } = this;
    const req =
      resolve_msg === "Others" || resolve_msg === "Wrong Entry" ? true : false;
    const { TabPane } = Tabs;
    const detailProp = {
      numberPlateOnclick,
      visitorOnclick,
    };
    const { status } = props;

    if (status === "Raised") {
      return (
        <div style={styles.container}>
          <Row style={{ display: des_display, justifyContent: des_content }}>
            <Col
              xs={24}
              sm={16}
              style={{ padding: "0px 0px 10px 10px", width: "80%" }}
            >
              <p style={styles.description}>{description}</p>
            </Col>
          </Row>
          <Row>
            <Tabs defaultActiveKey="1">
              <TabPane
                tab={<span style={styles.detailsTab}>Details</span>}
                key="1"
              >
                <Row>
                  <Col
                    xs={24}
                    sm={props.resolved_by ? 17 : 24}
                    style={{ padding: "0px 0px 20px 10px" }}
                  >
                    {props.image_path && (
                      <Col xs={24} sm={22} md={22} style={styles.ColStyle}>
                        <img
                          src={
                            props.s3_image_path
                              ? props.s3_image_path
                              : `${apiHost}${props.image_path}`
                          }
                          alt=""
                          align="center"
                          style={{ width: "90%", marginTop: "1rem" }}
                        />
                      </Col>
                    )}
                  </Col>
                </Row>
                <Row>
                  {[vehicleDetails].length>0 && (
                    <Col xs={24} sm={12}>
                      <h2
                        style={{
                          textAlign: "initial",
                          marginBottom: "0%",
                          marginTop: "1%",
                        }}
                      >
                        <p style={alertStyle.detailStyle}>Visitor Details</p>
                      </h2>
                      <div
                        style={{
                          borderBottom: "1px solid gray",
                          marginRight: "5%",
                          marginBottom: "1%",
                        }}
                      ></div>
                      
                        <>
                          <Row
                            xs={24}
                            sm={24}
                            md={24}
                            style={{ padding: "10px 0px 0px 10px" }}
                          >
                            <div style={{ display: "flex", color: "#393A3B" }}>
                              <h2 style={alertStyle.entryInfo}>
                                Number Plate
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "2.5rem",
                                  }}
                                >
                                  :
                                </span>
                              </h2>
                              <p
                                style={{
                                  fontSize: "16px",
                                  marginLeft: "5px",
                                  fontWeight: "bolder",
                                }}
                              >
                                {presence(vehicleDetails?.number_plate)}
                              </p>

                             
                                  {vehicleDetails?.number_plate && (
                                    <IoMdOpen
                                      style={alertStyle.phoneNoIcon}
                                      onClick={numberPlateOnclick}
                                    ></IoMdOpen>
                                  )}
                            </div>
                          </Row>
                          <Row
                            xs={24}
                            sm={24}
                            md={24}
                            style={{ padding: "10px 0px 0px 10px" }}
                          >
                            <div style={{ display: "flex", color: "#393A3B" }}>
                              <h2 style={alertStyle.entryInfo}>
                                Vehicle Type
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "2.5rem",
                                  }}
                                >
                                  :
                                </span>
                              </h2>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "bolder",
                                  marginLeft: "5px",
                                }}
                              >
                                {presence(vehicleDetails?.vehicle_type)}
                              </p>


                            </div>
                          </Row>
                        </>
                      
                    </Col>
                  )}
                </Row>
                {props.entity && <AlertInfo {...props} {...detailProp} />}
              </TabPane>

              {!changeColor && (
                <TabPane
                  tab={<span style={styles.resolveTab}>Resolve</span>}
                  key="2"
                >
                  <div style={styles.resolveLine}>
                    Here alerts are raised for unique reasons and it can be
                    resolved by choosing one of the reasons below
                  </div>
                  <div style={styles.resolveReason}>
                    Comment the reason why this alert should be resolved
                  </div>
                  <Row>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      style={{
                        padding: "20px 10px",
                        textAlign: "left",
                      }}
                    >
                      <FormControl style={{ width: "70%" }}>
                        <InputLabel id="demo-customized-select-label">
                          Remarks
                        </InputLabel>
                        <Select
                          labelId="demo-customized-select-label"
                          id="demo-customized-select"
                          value={resolve_msg}
                          onChange={handleChangeSelect}
                        >
                          {this.vehicleOption.map((o, index) => (
                            <MenuItem key={index} value={o}>
                              {o}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {this.state.resolve_msg === "Others" ? (
                        <div style={{ width: "70%", textAlign: "left" }}>
                          <SimpleTextInput
                            labelText="Remarks"
                            name="remark_msg"
                            value={remark_msg}
                            onChange={handleChange}
                            multi={true}
                            rows={2}
                            required={req}
                            color="black"
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  <div style={{ textAlign: "right", margin: "0 4rem 0 0" }}>
                    <Button
                      variant="contained"
                      disabled={
                        req ? (remark_msg.length === 0 ? true : false) : false
                      }
                      onClick={handleSubmit}
                      style={styles.buttonStyle}
                      autoFocus
                    >
                      Submit
                    </Button>
                  </div>
                </TabPane>
              )}
            </Tabs>
          </Row>
        </div>
      );
    } else {
      return (
        <div style={styles.container}>
          <Row>
            <div>
              <h3 style={{ textAlign: "left", fontSize: "20px" }}>
                {description}
              </h3>
              {props.resolved_by && (
                <Col xs={24} sm={7}>
                  <div style={styles.resolvedStyle}>
                    <p>Resolved by </p>
                    <h3 style={{ fontWeight: "bolder", padding: "0" }}>
                      {props.resolved_by.first_name}
                    </h3>
                    {props.resolved_at && (
                      <p>at {formatTime(props.resolved_at)}</p>
                    )}
                  </div>
                </Col>
              )}
              {props.image_path && (
                <Col xs={24} sm={22} md={22} style={styles.ColStyle}>
                  <div style={{ maxWidth: "70%" }}>
                    <img
                      src={
                        props.s3_image_path
                          ? props.s3_image_path
                          : `${apiHost}${props.image_path}`
                      }
                      alt=""
                      align="center"
                      style={{ width: "150%" }}
                    />
                  </div>
                </Col>
              )}
            </div>
          </Row>
          {props.entity && <AlertInfo {...props} {...detailProp} />}
        </div>
      );
    }
  }
}
export default AlertUpdate;
