import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { setCookie } from "helpers";
import AppBar from "components/AppBar";
import EntryList from "./AboutVehicleEntries/Sections/EntryList";
import AboutVehicle from "sections/aboutVehicleEntries/aboutVehicle";

class AboutVehicleEntries extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  componentWillUnmount() {
    setCookie("usePrevStateValues", "true");
    // setCookie("vehicle_entries", "true");
  }

  render() {
    const { number_plate } = this.props.match.params;

    return (
      <React.Fragment>
        <AppBar />
        <AboutVehicle number_plate={number_plate} />
        <EntryList number_plate={number_plate} />
      </React.Fragment>
    );
  }
}

export default withRouter(AboutVehicleEntries);
