import React, { Component } from "react";
import AppBar from "components/AppBar";
import { columns } from "../data/smVehicle";
import CRUDSmVehicles from "../components/form/smvehicles/CRUD";
import { handleResponse } from "components/notifications/handleResponse";
import { fetchListingData } from "api/smVehicle";
import { createAccess, updateAccess, debounce } from "helpers";
import PaginationTable from "components/paginationTables/withAddEdit";
import { allSmVehicleStatus } from "../config";
import { Row } from "antd";

class SmVehicle extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      rowData: undefined,
      formOpen: false,
      skeleton: true,
    };
  }

  initialRequestParams = () => {
    return {
      perPage: 25,
      page: 1,
      search: "",
      status: allSmVehicleStatus,
    };
  };

  componentDidMount = () => {
    this.fetchTableData();
  };

  fetchTableData = () => {
    fetchListingData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  flushTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    this.flushTimer();
    this.timer = setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
    });
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.status =
      filters.status && filters.status.length > 0
        ? filters.status
        : allSmVehicleStatus;
    this.fetchTableData();
  };

  createAction = () => {
    return createAccess("sm_vehicles");
  };

  updateAction = () => {
    return updateAccess("sm_vehicles");
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  render() {
    const { data, rowData, formOpen, skeleton } = this.state;
    const { onChange, handleSubmitSuccess, handleClose } = this;
    const appBarProps = {
      search: this.requestParams.search,
      // searchOpen: true,
      onChange,
    };

    const tableProps = {
      tabColor: "info",
      title: "Senior Management Vehicle",
      subTitle: "Add Senior Management Vehicle",
      columns: columns,
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick: (row) => {
        this.setState({ formOpen: true, rowData: row });
      },
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      dataValue: "ADD SM Vehicle",
      skeleton,
      createAction: this.createAction(),
      updateAction: this.updateAction(),
    };

    const CRUDProps = {
      data: rowData,
      onCancel: handleClose,
      handleSubmitSuccess,
      open: formOpen,
      tabColor: "info",
      onChange,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        <CRUDSmVehicles {...CRUDProps} />
      </React.Fragment>
    );
  }
}

export default SmVehicle;
