import React, { Component } from "react";
import { Button, Modal, Col, Form, Checkbox } from "antd";
import Select from "react-select";

class VisitAlerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      data: {
        vehicle_type: this.props.vehicle_type,
        number_plate: this.props.number_plates,
        phone: this.props.phone_nos,
        other_filter: this.props.other_filter,
        loader: true,
      },
    };
  }

  handleOk = () => {
    this.props.visitFilterChange(this.state.data);
    this.props.visitFilterOnClick();
  };

  handleReset = () => {
    let data = {
      phone: [],
      vehicle_type: ["Normal", "Taxi", "Motor Bike", "Special Vehicle"],
      number_plate: [],
      other_filter: [],
    };
    this.props.visitFilterChange(data);
    this.props.visitFilterOnClick();
  };

  handleSelectChange = (name, value) => {
    let { data } = this.state;
    data[name] = value;
    this.setState({
      data,
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.visitFilterOnClick();
  };

  render() {
    const options = ["Normal", "Taxi", "Motor Bike", "Special Vehicle"];
    const filterOptions = ["Entry With Alert Details"];

    return (
      <React.Fragment>
        {this.state.visible && (
          <Modal
            title="Filter Values"
            visible={this.state.visible}
            onCancel={this.handleCancel}
            bodyStyle={{ padding: "24px 24px 279px 24px" }}
            maskClosable={false}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={this.handleReset}
                style={{
                  background: "#3b8bf7",
                  color: "white",
                  borderColor: "#3b8bf72",
                }}
              >
                Reset
              </Button>,
              <Button
                key="back"
                onClick={this.handleOk}
                style={{
                  background: "#3b8bf7",
                  color: "white",
                  borderColor: "#3b8bf7",
                }}
              >
                Apply
              </Button>,
            ]}
            width="790px"
            style={{
              height: "160%",
              marginLeft: "20%",
              position: "fixed",
              width: "750px",
              transformOrigin: "1252.81px -70px",
            }}
          >
            <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
              <p
                style={{
                  fontSize: 11,
                  margin: "-12px 0 0.3em",
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              >
                Number Plate
              </p>
              <Select
                isMulti
                value={this.state.data.number_plate}
                options={this.props.number_plate}
                onChange={(value) => {
                  this.handleSelectChange("number_plate", value);
                }}
                closeMenuOnSelect={false}
                isClearable={false}
              />
            </Col>
            <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
              <div
                style={{
                  margin: "-12px 0 -12px 0",
                  padding: "25px 0 25px",
                }}
              >
                <p
                  style={{
                    fontSize: 11,
                    margin: "0.1em 0 -0.7em",
                    color: "rgba(0, 0, 0, 0.54)",
                    padding: "1px 0 15px 0",
                  }}
                >
                  Phone
                </p>
                <Select
                  style={{ margin: "-6px 0px -6px 0px" }}
                  isMulti
                  value={this.state.data.phone}
                  options={this.props.phone}
                  onChange={(value) => {
                    this.handleSelectChange("phone", value);
                  }}
                  closeMenuOnSelect={false}
                  isClearable={false}
                />
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
              <Form.Item>
                <p
                  style={{
                    fontSize: 11,
                    margin: "0.1em 0 -0.7em",
                    color: "rgba(0, 0, 0, 0.54)",
                    padding: "5px 0 0 0",
                  }}
                >
                  Vehicle Type
                </p>
                <Checkbox.Group
                  options={options}
                  value={this.state.data.vehicle_type}
                  name="vehicle_type"
                  onChange={(value) => {
                    this.handleSelectChange("vehicle_type", value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
              <Form.Item>
                <p
                  style={{
                    fontSize: 11,
                    margin: "-2.1em 0 -0.7em",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  Other Filter
                </p>
                <Checkbox.Group
                  options={filterOptions}
                  value={this.state.data.other_filter}
                  onChange={(value) => {
                    this.handleSelectChange("other_filter", value);
                  }}
                />
              </Form.Item>
            </Col>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default VisitAlerts;
