import React, { Component, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import GoogleAnalytics from "react-ga";
import { Login, Forget } from "views";
import { isAuthenticated, presentationAccess, getCookie } from "helpers";
import { GoogleAnalyticsID } from "config";
import { filteredMenu } from "data/menus";
import { makeStyles } from "@material-ui/styles";
import Slider from "components/Slider";
import { UserProfile, AboutVehicleEntries, AboutVisitorEntries } from "views";
import { menu as alertMenus } from "views/Alerts-Module/data/alert-menus";
import AlertOverview from "views/Alerts-Module/index";
import ShowImage from "views/ShowImage";
export default class CustomRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSlider: [],
      loadComponent: false,
      defaultRoute: window.location.pathname,
      routes: [
        {
          index: "1-1",
          url: () => "/user-profile",
          match_url: "/user-profile",
          render: (props) => <UserProfile {...props} />,
          slider: true,
          slideShow: true,
        },
        {
          index: "1-6",
          url: (number_plate) => `/vehicles/${number_plate}`,
          match_url: "/vehicles/:number_plate",
          render: (props) => <AboutVehicleEntries {...props} />,
          slider: true,
          slideShow: true,
        },
        {
          index: "1-6",
          url: (phone) => `/outsiders/${phone}`,
          match_url: "/outsiders/:phone",
          render: (props) => <AboutVisitorEntries {...props} />,
          slider: true,
          slideShow: true,
        },
        {
          index: "1-7",
          url: (phone) => `/employees/${phone}`,
          match_url: "/employees/:phone",
          render: (props) => <AboutVisitorEntries {...props} />,
          slider: true,
          slideShow: true,
        },
      ],
    };
  }

  async componentDidMount() {
    if (isAuthenticated()) {
      let defaultRoute;
      let { routes } = this.state;
      let showSlider = routes.filter((o) => o.slider).map((o) => o.match_url);
      let menuGroups = await filteredMenu();
      menuGroups.forEach((menuGroup) =>
        menuGroup.children.forEach((menus) => {
          menus.children.forEach((menu) => {
            routes.push(menu);
            showSlider.push(menu.match_url);
            if (!defaultRoute) {
              defaultRoute = menu.match_url;
            }
          });
        })
      );

      if (presentationAccess()) {
        let alerts = await alertMenus();

        routes = [
          {
            id: "overview",
            index: "9-0",
            type: [],
            url: () => "/alerts/overview",
            match_url: "/alerts/overview",
            render: (props) => <AlertOverview {...props} />,
          },
          ...alerts.filter((o) => !o.disabled),
          ...routes,
        ];
        if (getCookie("home_page") === "presentation") {
          defaultRoute = "/alerts/overview";
        }
      }

      this.setState({
        routes,
        showSlider,
        defaultRoute: defaultRoute || this.state.defaultRoute,
        loadComponent: true,
      });
    }
  }

  render() {
    const { defaultRoute, routes } = this.state;
    return (
      <Switch>
        <PublicRoute
          exact
          path="/"
          component={Login}
          defaultRoute={defaultRoute}
        />
        <PublicRoute
          exact
          path="/forgot-password"
          component={Forget}
          defaultRoute={defaultRoute}
        />
        <PublicRoute
          path="/images/alerts"
          component={ShowImage}
          defaultRoute={defaultRoute}
        />

        {this.state.loadComponent &&
          routes.map((route, i) => (
            <PrivateRoute
              showSlider={route.slideShow}
              // showSlider={showSlider.includes(route.match_url)}
              key={i}
              exact
              path={route.match_url}
              index={route.index}
              component={route.render}
            />
          ))}
        <Route
          component={() => {
            return isAuthenticated() ? (
              <Redirect to={defaultRoute} />
            ) : (
              <Redirect to="/" />
            );
          }}
        />
      </Switch>
    );
  }
}

GoogleAnalytics.initialize(GoogleAnalyticsID);

const trackPage = (page) => {
  GoogleAnalytics.set({ page });
  GoogleAnalytics.pageview(page);
};

const PrivateRoute = ({ component: Component, showSlider, ...rest }) => {
  const pathname = window.location.pathname;
  useEffect(() => trackPage(pathname), [pathname]);
  const classes = useStyles();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          showSlider ? (
            <div className={classes.root}>
              <Slider index={rest.index} />
              <main className={classes.content}>
                <div className={classes.toolbar} />
                {Component(props)}
              </main>
            </div>
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const PublicRoute = ({ defaultRoute, component: Component, ...rest }) => {
  const pathname = window.location.pathname;

  useEffect(() => trackPage(pathname), [pathname]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated() ? (
          <Redirect to={defaultRoute} />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    width: 0,
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
