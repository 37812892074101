import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  let names = "";
  let phone_no = "";
  let device = "";
  let created_bys = "";
  const { name, phone, device_name, created_by } = params;
  if (name) {
    names = name.map((value) => `&name[]=${value.value}`).join("");
  }
  if (phone && phone.length > 0) {
    phone_no = phone.map((key) => `&phone[]=${key.label}`).join("");
  }
  if (device_name) {
    device = device_name.map((key) => `&device_name[]=${key.label}`);
  }
  if (created_by) {
    created_bys = created_by
      .map((key) => `&created_by[]=${key.label}`)
      .join("");
  }
  return `from_time=${params.fromTime}&to_time=${
    params.toTime
  }${names}${phone_no}${device}${created_bys}&page=${params.page ||
    1}&per_page=${totalEntries || params.perPage}`;
}

export function fetchEmployeesEntry(requestParams) {
  return makeRequest({
    uri: `/api/v1/employee_entries?${generateParams(requestParams)}`,
  });
}

export function fetchEmployeesEntryData(id) {
  return makeRequest({
    uri: `/api/v1/employee_entries?employee_id=${id}`,
  });
}

export function aggregation(params) {
  return makeRequest({
    uri: `/api/v1/employee_entries/aggs?from_time=${params.fromTime}&to_time=${params.toTime}`,
  });
}

export function triggerReportDownload(requestParams, totalEntries) {
  return makeRequest({
    uri: `/api/v1/employee_entries/generate_report?${generateParams(
      requestParams,
      totalEntries
    )}`,
  });
}
