import config from "config.json";
import data from "data.json";
import Honeybadger from "honeybadger-js";

export const apiHost = config.host;
export const webSocketHost = config.web_socket_host;
export const siteName = config.site_name;
export const showImgtimeline = config.show_timeline_img;
export const showLnprNumberPlate =
  config.show_lnpr_numberplate === false ? false : true;
export const showSubTitle = config.show_menu_subtitle;
export const showExpandedMenu = config.show_expanded_menu;
export const enableAnnouncement = config.announcement || false;
export const specialVehicleProps = ["SmVehicle", "VipVehicle"];

export const loginImage = config.login_image;

export const allAlertSettingStatus = data.alert_setting_status || [
  "active",
  "inactive",
];
export const allAlertSettingNature = data.alert_setting_nature || [
  "normal",
  "unit",
  "cluster",
];
export const alertStatus = data.alert_status || ["Raised", "Resolved"];

export const ParkingUnauthorized = data.parkingunauthorized || [
  {
    type: [
      "parking.unauthorized",
      "special.vehicle",
      "visitor.blacklist",
      "vehicle.blacklist",
      "pickup.overstay",
      "sm_vehicle.entry",
      "vehicle.traffic",
    ],
    title: "Unauthorized Parking Logs",
    subtitle: "Records of Unauthorized Parking Logs",
    show: true,
  },
];

export const OccupantAlerts = data.occupant || {
  group: ["People Occupant"],
  title: "People Occupant Logs",
  subtitle: "Records of People Identified in Restricted Area",
  show: false,
};

export const PerimeterAlerts = data.perimeter || {
  group: ["Perimeter Crossing"],
  title: "Perimeter Crossing Log",
  subtitle: "Records of People who identify near Fencing",
  show: false,
};

export const SosAlerts = data.sosalerts || {
  group: ["SoS"],
  title: "Emergency Alerts",
  subtitle: "List of Emergency Alerts",
  show: false,
};

export const RobotAlerts = data.robotAlerts || [
  {
    type: [
      "sitting-area-detection",
      "intrusion.detection",
      "mask-camera-1.detection",
      "docking.occupant",
      "sitting-area-social.detection",
      "unattended.objects",
      "smoking-area.occupant",
      "robot.stuck",
      "special.vehicle",
      "sm_vehicle.entry",
      "camera.disconnected",
    ],
    title: "Robot Alerts",
    subtitle: "Records of Robot alerts",
    show: true,
  },
];

export const CameraAlerts = data.cameraAlerts || [
  {
    type: [
      "vehicle.traffic",
      "sitting-area-detection",
      "ipr_lnpr",
      "unattended.objects",
      "mask-camera-1.detection",
      "crowd_detection",
      "docking.occupant",
      "sitting-area-social.detection",
    ],
    title: "Camera Alerts",
    subtitle: "Records of camera alerts",
    show: true,
  },
];

export const OtherAlerts = data.other || [
  {
    title: "Alerts",
    subtitle: "List of Other Alerts which are not present in above",
    show: false,
  },
];

export const AlertResolveOptions = data.alert_resolve_option || [
  "Security officer patrolling",
  "MPA staff",
  "Item blocking",
  "Passenger",
  "Rubbish",
  "Workers stuff",
  "Taken the object",
  "Attend resolved",
  "Checked and resolved",
  "False Alert",
  "MA Permission / Approval",
  "Contractor",
  "Breakdown",
  "Others"
];

export const SpecialVehicleResolve = [
  "Police-Patrolling",
  "ICA-Patrolling",
  "Incident Report Created"
]

/* Blacklist*/
export const allBlackListsStatus = data.blacklists_status || [
  "active",
  "inactive",
];

/*SmVehicle*/
export const allSmVehicleStatus = data.sm_vehicle_status || [
  "active",
  "inactive",
];

/* Contractor Card */

export const allCardstatus = ["assigned", "available", "lost"];

/** Dashboard */
export const dashboardGraph = data["dashboard-graph"] || [
  "visitor_type",
  "visitor_nature",
  "raised_alerts",
];
export const dashboardTiles = data["dashboard-tiles"] || [
  "vehicles_in",
  "vehicles_out",
  "total_vehicles",
];

/* FCM */
export const enable_fcm = config.enable_fcm;
export const fcm_notification_id = config.fcm_notification_id;
export const fcm_notification_key = config.fcm_notification_key;

/* Google Analytics */
export const GoogleAnalyticsID = config.google_analytics_id;

/*GrayLog */
export const enableGraylog = config.send_logs_to_graylog;
export const graylogFacility = config.graylog_facility;
export const graylogUrl = config.graylog_url;
export const graylogHost = config.graylog_host;

/* HoneyBadger */
export const honeyBadger = Honeybadger.configure({
  apiKey: "babafb53",
  developmentEnvironments: ["development", "dev", "test"],
  environment: graylogHost,
  disabled: "development" === (graylogHost || "development"),
});

/* Notification */

export const allNotificationTypes = data.notification_types || {
  private: "locked",
  public: "free",
};
export const allNotificationstatus = data.notification_status || [
  "active",
  "inactive",
];

/* Reports */

export const allReportTypes = data.report_types || {
  private: "locked",
  public: "free",
};
export const allReportStatus = data.report_status || ["active", "inactive"];

/* Role */

export const roleType = data.role_type || ["User", "Bot"];

/* Schedulers */

export const scheduledTime = [
  { label: "Every Minute", value: "every_minute" },
  { label: "Every 5 Mins", value: "every_5_mins" },
  { label: "Every 10 Mins", value: "every_10_mins" },
  { label: "Every 15 Mins", value: "every_15_mins" },
  { label: "Every 30 Mins", value: "every_30_mins" },
  { label: "Every Hour", value: "every_hour" },
  { label: "Every 6 Hours", value: "every_6_hours" },
  { label: "Twice Daily", value: "twice_daily" },
  { label: "Daily", value: "daily" },
];

/* Sent Alerts */

export const allMessageTypes = data.sent_alert_types || [
  "mail",
  "sms",
  "whatsapp",
  "ivr",
];

/* Visitors */
export const allVisitorInviteStatuses = data.invite_statuses;

export const allBotRoleTypes = {
  "LNPR-Bot": "LNPR-Bot",
  "VMS-Bot": "VMS-Bot",
};

export const purpose = {
  LnprCamera: "LnprCamera",
  AlertCamera: "AlertCamera",
};

export const kioskType = [
  { label: "VMS", value: "vms" },
  { label: "HANDHELD", value: "hand_vms" },
  { label: "SK", value: "kiosk" },
];

export const vizitinType = [
  { label: "Plus", value: "vizitin_plus" },
  { label: "Pro", value: "vizitin_pro" },
];

export const softwareType = [
  { label: "VMS", value: "vms" },
  { label: "SK", value: "kiosk" },
  { label: "HANDHELD", value: "hand_vms" },
];

export const softwareFilter = {
  VMS: "vms",
  SK: "kiosk",
  HANDHELD: "hand_vms",
};

export const kioskFilter = {
  VMS: "vms",
  SK: "kiosk",
  HANDHELD: "hand_vms",
};

export const vizitinFilter = {
  Plus: "vizitin_plus",
  Pro: "vizitin_pro",
};

export const vehicleData = [
  { label: "Normal", value: "normal" },
  { label: "Taxi", value: "taxi" },
  { label: "Heavy Vehicle", value: "heavy_vehicle" },
  { label: "Private Hire", value: "private_hire" },
  { label: "Motor Bike", value: " motor_bike" },
  { label: "Special Vehicle", value: "special_vehicle" },
];
