import React, { Component } from "react";
import Form from "./Form";
import DialogForm from "hoc/dialogForm";
import { createStaffs, updateStaffs } from "api/staffs";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      nricPrier: "XXXXX",
      data: {
        name: "",
        department: "",
        status: "active",
        phone: "",
        country_code: 65,
        fin_number: "",
      },
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const { data } = this.state;
    this.setState({ data: { ...data, [name]: value } });
  };

  handleChangeNric = (value) => {
    let { data, nricPrier } = this.state;
    let { fin_number } = data;
    let fin = fin_number
      ? value.slice(0, 9)
      : `${nricPrier}${value.slice(0, 4)}`;
    this.setState({ data: { ...data, fin_number: fin } });
  };

  componentDidMount = () => {
    if (this.props.data) {
      const {
        name,
        department,
        id,
        status,
        phone,
        country_code,
        fin_number,
      } = this.props.data;
      this.id = id;
      this.setState({
        data: {
          ...this.state.data,
          name,
          department,
          status,
          phone,
          country_code,
          fin_number,
        },
      });
    }
  };

  requestParams = () => {
    const {
      name,
      department,
      status,
      phone,
      country_code,
      fin_number,
    } = this.state.data;
    return {
      staffs: {
        name,
        department,
        status,
        phone,
        country_code,
        fin_number,
      },
    };
  };

  onSubmit = () => {
    const {
      id,
      requestParams,
      props: { submitClick, handleSubmitSuccess },
    } = this;
    const method = id ? updateStaffs : createStaffs;

    submitClick();
    method(requestParams(), id)
      .then((data) => {
        success(data.message);
        handleSubmitSuccess();
        submitClick();
      })
      .catch((error) => {
        submitClick();
        handleResponse(error);
      });
  };

  render() {
    const { handleChange, handleChangeNric } = this;
    const { data } = this.state;
    const staffProps = {
      handleChange,
      handleChangeNric,
      data,
    };
    return <Form {...staffProps} />;
  }
}

export default DialogForm("Company")(CRUD);
