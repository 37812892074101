import makeRequest from ".";

function generateParams(params = {}) {
  return `query=${params.search}&page=${params.page || 1}&per_page=${
    params.perPage
  }`;
}

export function fetchEmployeesData(requestParams) {
  return makeRequest({
    uri: `/api/v1/employees?${generateParams(requestParams)}`,
  });
}

export function createEmployee(requestBody) {
  return makeRequest({
    uri: `/api/v1/employees`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateEmployee(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/employees/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}
