import React, { Component } from "react";
import { debounce } from "helpers";
import { columns } from "data/registerCard";
import AppBar from "components/AppBar";
import { fetchRegisteredData } from "api/cardRegister";
import { BsUpload } from "react-icons/bs";
import PaginationTable from "components/paginationTables/withAddEdit";
import CRUDRegister from "components/form/registerCard/CRUD";
import { Row } from "antd";
import { handleResponse } from "components/notifications/handleResponse";
import { createAccess, updateAccess, uploadAccess } from "helpers";
import CRUDUpload from "components/form/registerCard/CRUDUpload";

class CardRegister extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      registeredData: undefined,
      formOpen: false,
      skeleton: true,
      upload: false,
    };
  }

  initialRequestParams = () => {
    return {
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount = () => {
    this.fetchTableData();
  };

  fetchTableData = () => {
    fetchRegisteredData(this.requestParams)
      .then(({ data }) => {
        this.setState({
          data: data,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    setTimeout(() => {
      this.fetchTableData();
    }, 3000);
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      RegisteredData: undefined,
      upload: false,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  editRegisteredCard = (row) => {
    let access = this.updateAction();
    if (access) {
      this.setState({ formOpen: true, RegisteredData: row });
    }
  };

  onRowClick = (row) => {
    let access = this.updateAction();
    if (access) {
      this.setState({ formOpen: true, rowData: row });
    }
  };

  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.fetchTableData();
  };

  createAction = () => {
    return createAccess("contractors");
  };

  updateAction = () => {
    return updateAccess("contractors");
  };
  uploadAction = () => {
    return uploadAccess("contractors");
  };

  render() {
    const { data, RegisteredData, formOpen, skeleton, upload } = this.state;
    const {
      onChange,
      handleClose,
      handleSubmitSuccess,
      handleTableChange,
      editRegisteredCard,
    } = this;

    const CRUDProps = {
      onCancel: handleClose,
      open: formOpen,
      data: RegisteredData,
      handleSubmitSuccess,
      tabColor: "warning",
    };

    const appBarProps = {
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
    };

    const tableProps = {
      skeleton,
      normalIcon: <BsUpload />,
      tabColor: "warning",
      title: "Registered Contractor",
      subTitle: "Registered Contractor ",
      pagination: this.pagination(),
      columns: columns(editRegisteredCard),
      data: data,
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      OnClickUpload: () => {
        this.setState({ upload: true });
      },
      dataValue: "Add Contractors",
      dataValueUpload: "Upload",
      createAction: this.createAction(),
      uploadAction: this.uploadAction(),
      handleTableChange,
    };

    const CRUDUploadProps = {
      open: upload,
      titleText: "Contractor Data",
      onCancel: handleClose,
      handleSubmitSuccess,
      upload: true,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        {formOpen && <CRUDRegister {...CRUDProps} />}
        <CRUDUpload {...CRUDUploadProps} />
      </React.Fragment>
    );
  }
}

export default CardRegister;
