import React, { Component } from "react";
import AppBar from "components/AppBar";
import { Row } from "antd";
import { columns } from "data/upcoming";
import PaginationTable from "components/paginationTables/withAddEdit";
import CRUDUpcoming from "components/form/upcoming/CRUD";
import Joystick from "assets/img/gamepad.svg";
import Pause from "assets/img/pause.svg";
import Stop from "assets/img/stop.svg";
import { updateAccess } from "helpers";
import { webSocketHost } from "config";

class Upcoming extends Component {
  constructor(props) {
    super(props);
    this.rosSocket = new WebSocket(webSocketHost);
    this.state = {
      skeleton: true,
      data: [],
      formOpen: false,
      rescheduleData: undefined,
      joystick_status: undefined,
      isPaused: undefined,
      isEmergency: undefined,
    };
  }

  fetchTableData = () => {
    this.rosSocket.onopen = () => {
      this.rosSocket.send(
        '{"channel":"non-ros","msg_type" : "request","type" : "topic","topic" : "feedback"}'
      );
      this.rosSocket.send(
        `{"channel":"non-ros","msg_type":"request","type":"service","service":"database","request_type":"upcoming_data","data":{"fetch":"upcoming"}}`
      );
      this.rosSocket.send(
        '{"channel":"non-ros","msg_type":"request","type":"service","service":"state","request_type":"control_buttons_state","data":{}}'
      );
    };
    this.rosSocket.onmessage = (e) => {
      let parsedData = JSON.parse(e.data);
      if (parsedData?.request_type === "upcoming_data") {
        this.setState({
          data: parsedData?.records?.schedules,
          skeleton: false,
        });
      }
      if (parsedData?.request_type === "control_buttons_state") {
        let stateData = JSON.parse(parsedData.state);
        this.setState({
          joystick_status: stateData?.joystick,
          isPaused: stateData?.paused,
          isEmergency: stateData?.emergency,
        });
      }
    };
  };

  componentDidMount() {
    this.fetchTableData();
  }

  editSchedule = (row) => {
    this.setState({ formOpen: true, rescheduleData: row });
  };

  deleteSchedule = (row) => {
    const { id } = row;
    this.rosSocket.send(
      `{"channel":"non-ros","msg_type":"request","type":"service","service":"command","data":{"mode":"save","type":"skip_schedule","command":{"schedule_inst_id":"${id}"}}}`
    );
    window.location.reload();
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      rescheduleData: undefined,
    });
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    window.location.reload();
  };

  updateAction = () => {
    updateAccess("upcoming");
  };

  joystickHandler = () => {
    this.rosSocket.send(
      `{"channel":"non-ros","msg_type":"request","type":"service","service":"safety","data":{"action":"joystick","command":"${!this.state.joystick_status ? "on" : "off"
      }"}}`
    );
    this.rosSocket.onmessage = (e) => {
      let parsedData = JSON.parse(e.data);
      let getResponse = parsedData?.response;
      let parsedResponse = JSON.parse(getResponse);
      let status = parsedResponse?.state?.joystick;
      this.setState({ joystick_status: status });
    };
    window.location.reload();
  };

  pauseHandler = () => {
    this.rosSocket.send(
      `{"channel":"non-ros","msg_type":"request","type":"service","service":"safety","data":{"action":"${this.state.isPaused ? "resume_plan" : "pause_plan"
      }","actual_pause_resume":true}}`
    );
    this.rosSocket.onmessage = (e) => {
      let parsedData = JSON.parse(e.data);
      let getResponse = parsedData?.response;
      let parsedResponse = JSON.parse(getResponse);
      let status = parsedResponse?.state?.paused;
      this.setState({ isPaused: status });
    };
    window.location.reload();
  };

  emergencyHandler = () => {
    this.rosSocket.send(
      `{"channel":"non-ros","msg_type":"request","type":"service","service":"safety","data":{"action":"emergency","command":"${this.state.isEmergency ? "off" : "on"
      }"}}`
    );
    this.rosSocket.onmessage = (e) => {
      let parsedData = JSON.parse(e.data);
      let getResponse = parsedData?.response;
      let parsedResponse = JSON.parse(getResponse);
      let status = parsedResponse?.state?.emergency;
      this.setState({ isPaused: status });
    };
    window.location.reload();
  };

  render() {
    const { skeleton, data, formOpen, rescheduleData } = this.state;
    const { handleClose, handleSubmitSuccess } = this;
    const CRUDProps = {
      onCancel: handleClose,
      open: formOpen,
      schedule_update: rescheduleData,
      rosSocket: this.rosSocket,
      tabColor: "info",
      handleSubmitSuccess,
    };

    const tableProps = {
      tabColor: "info",
      title: "Upcoming",
      subTitle: "Upcoming schedules are listed here",
      columns: columns(
        { data: data },
        this.editSchedule,
        this.updateAction,
        this.deleteSchedule
      ),
      data: data,
      onRowClick: () => { },
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      skeleton,
    };

    return (
      <React.Fragment>
        <AppBar></AppBar>
        <Row>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <span
              onClick={this.joystickHandler}
              style={{
                cursor: "pointer",
                marginRight: "20px",
                textAlign: "center",
              }}
            >
              <img src={Joystick} />
              <p>Joystick</p>
            </span>
            <span
              onClick={this.pauseHandler}
              style={{
                cursor: "pointer",
                marginRight: "20px",
                textAlign: "center",
              }}
            >
              <img src={Pause} />
              <p>Pause</p>
            </span>
            <span
              onClick={this.emergencyHandler}
              style={{
                cursor: "pointer",
                marginRight: "20px",
                textAlign: "center",
              }}
            >
              <img src={Stop} />
              <p>Stop</p>
            </span>
          </div>
          <PaginationTable {...tableProps} />
        </Row>
        {formOpen && <CRUDUpcoming {...CRUDProps} />}
      </React.Fragment>
    );
  }
}
export default Upcoming;
