import React, { Component } from "react";
import DialogForm from "hoc/dialogForm";
import Form from "./Form";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import { createSmVehicle, updateSmVehicle } from "api/smVehicle";
import { createVehicle } from "api/vehicles";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      data: {
        name: "",
        status: "active",
        remarks: "",
        vehicle_id: "",
        vehicle: null,
      },
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const { data } = this.state;
    this.setState({
      data: { ...data, [name]: value },
    });
  };

  requestParams = () => {
    const { status, vehicle, remarks, name } = this.state.data;
    let vehicle_id = vehicle?.value || "";
    return {
      sm_vehicles: {
        name,
        vehicle_id: vehicle_id,
        status,
        remarks,
      },
    };
  };

  handleCreateVehicles = (value) => {
    createVehicle({ vehicles: { number_plate: value } })
      .then(({ data }) => {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            vehicle: { label: data.number_plate, value: data.id },
          },
        }));
      })
      .catch((error) => {
        handleResponse(error);
      });
  };
  onSubmit = () => {
    const {
      id,
      requestParams,
      props: { submitClick, handleSubmitSuccess },
    } = this;
    const method = id ? updateSmVehicle : createSmVehicle;

    submitClick();
    method(requestParams(), id)
      .then((data) => {
        success(data.message);
        handleSubmitSuccess();
        submitClick();
      })
      .catch((error) => {
        submitClick();
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    if (this.props.data) {
      const { id, vehicle, status, remarks, name } = this.props.data;
      this.id = id;
      let vehicles = { label: vehicle?.number_plate, value: vehicle?.id };
      this.setState({
        data: {
          ...this.state.data,
          name,
          status,
          remarks,
          vehicle: vehicles,
        },
      });
    }
  };

  render() {
    const { handleChange, handleCreateVehicles, id } = this;
    const { status, remarks, vehicle, name } = this.state.data;

    const props = {
      id,
      status,
      remarks,
      vehicle,
      name,
    };
    return (
      <Form
        handleChange={handleChange}
        {...props}
        handleCreateVehicles={handleCreateVehicles}
      />
    );
  }
}

export default DialogForm("SM Vehicle")(CRUD);
