import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const CRUDDateTimePicker = (props) => {
  const classes = useStyles();
  return (
    <TextField
      id="datetime-local"
      label="Reschedule to"
      type="datetime-local"
      value={props.value}
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={props.onChange}
    />
  );
};

export default CRUDDateTimePicker;
