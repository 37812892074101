import moment from "moment";
import { presence, titleize, timeDiff, formatTime } from "helpers";
import React from "react";
import { StatusComp } from "data/index";

export const columns = [
  {
    title: "Number Plate / Phone",
    dataIndex: "entity",
    align: "center",
    render: (entity) => {
      if (entity && entity.number_plate) {
        return `${presence(entity.number_plate)}`;
      }
      return `${presence(entity.phone)}`;
    },
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    align: "center",
    render: (created_at) => `${formatTime(created_at)}`,
  },
  {
    title: "Last Visited",
    dataIndex: "entity.latest_entry_at",
    align: "center",
    render: (latest_entry_at) => `${timeDiff(moment(), latest_entry_at)}`,
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    align: "center",
    render: (remarks) => `${presence(remarks)}`,
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    render: (status) => <StatusComp value={titleize(status)} />,
  },
];
