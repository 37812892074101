import React from "react";
import { formatTime, presence, timeDiff } from "helpers";
import moment from "moment";

export const columns = [
  {
    title: "Vehicle Number",
    dataIndex: "number_plate",
    width: "30%",
    render: (number_plate) => `${presence(number_plate)}`,
    align: "center",
  },
  {
    title: "Duration",
    dataIndex: "duration",
    width: "35%",
    render: (row) => `${timeDiff(row?.in_time, row?.out_time)}`,
    align: "center",
  },
  {
    title: "Out Time",
    dataIndex: "out_time",
    width: "35%",
    render: (out_time) => (
      <React.Fragment>
        <span style={{ display: "block" }}>{formatTime(out_time)}</span>
        <span>{timeDiff(moment(), out_time)}</span>
      </React.Fragment>
    ),
    align: "center",
  },
];
