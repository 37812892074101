import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import TableHeader from "./tableHeader";
import RowspanBody from "./rowspanBody";
class RowspanTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ loading: false });
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ loading: true });
    this.props.handleTableChange(pagination, filters, sorter);
  };

  render() {
    const {
      tabColor,
      title,
      subTitle,
      columns,
      data,
      pagination,
      handleOnClick,
      OnClickUpload,
      dataValue,
      onRowClick,
      dataValueUpload,
      skeleton,
      createAction,
      uploadAction,
      handlePaginationChange,
    } = this.props;

    const headerprops = {
      tabColor,
      title,
      subTitle,
      handleOnClick,
      OnClickUpload,
      dataValue,
      dataValueUpload,
      createAction,
      uploadAction,
    };
    const { loading } = this.state;

    const { handleTableChange } = this;
    const bodyprops = {
      columns,
      data,
      pagination,
      handleTableChange,
      handlePaginationChange,
      onRowClick,
      skeleton,
      loading,
    };
    return (
      <Paper className="paperClass">
        <TableHeader {...headerprops} />
        <RowspanBody {...bodyprops} />
      </Paper>
    );
  }
}

export default RowspanTable;
