import React from "react";
import { FormControl, FormLabel } from "@material-ui/core";
import { Radio } from "antd";
import { titleize, isArray } from "helpers";

const RadioButton = ({
  labelText,
  value,
  data,
  onChange,
  width,
  name,
  color,
}) => {
  if (isArray(data)) {
    let empty_obj = {};
    data.map((x) => (empty_obj[titleize(x)] = x));
    data = empty_obj;
  }

  return (
    <FormControl fullWidth>
      <FormLabel
        component="legend"
        style={{ fontSize: "10px", marginBottom: "4px" }}
      >
        {labelText}
      </FormLabel>
      <Radio.Group
        value={value}
        name={name}
        size="large"
        buttonStyle="solid"
        onChange={onChange}
        style={{ width: "100%" }}
      >
        {Object.keys(data).map((key, i) => (
          <Radio.Button
            key={i}
            value={data[key]}
            className={color ? "class-" + i : ""}
            style={{ textAlign: "center", width: width ? width : "" }}
          >
            {titleize(key)}
          </Radio.Button>
        ))}
      </Radio.Group>
    </FormControl>
  );
};

export default RadioButton;
