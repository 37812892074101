import React from "react";
import { presence, formatTime } from "helpers";

export const columns = [
  {
    title: "Name / Phone",
    align: "center",
    render: (_, row) => {
      return (
        <>
          <p> {presence(row.employee?.name)}</p>
          <strong>{presence(row.employee?.phone)}</strong>
        </>
      );
    },
  },
  {
    title: "Authenticated By",
    align: "center",
    dataIndex: "entry_mode",
    render: (obj) => {
      return <>{obj}</>;
    },
  },
  {
    title: "Entry Time",
    align: "center",
    render: (_, row) => {
      return <>{formatTime(row.in_time, "MMM D, YYYY, HH:mm:ss")}</>;
    },
  },
  {
    title: "Device Name",
    align: "center",
    render: (_, row) => {
      return <>{presence(row.device_name)}</>;
    },
  },
];
