import React from "react";
import { presence, titleize } from "helpers";
import { StatusComp } from "data/index";

export const columns = [
  {
    title: "Name",
    align: "center",
    width: "25%",
    render: (row) => {
      return `${presence(row.name)}`;
    },
  },
  {
    title: "Phone",
    align: "center",
    render: (row) => {
      return `${presence(row.phone)}`;
    },
  },
  {
    title: "Fin number",
    align: "center",
    render: (row) => {
      return `${presence(row.fin_number)}`;
    },
  },
  {
    title: "Department",
    dataIndex: "department",
    render: (department) => {
      return `${presence(department)}`;
    },
    align: "center",
    width: "25%",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    render: (status) => <StatusComp value={titleize(status)} />,
  },
];
