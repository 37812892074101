import moment from "moment";
import { presence, titleize, timeDiff, formatTime } from "helpers";
import React from "react";
import { StatusComp } from "data/index";

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    align: "center",
    render: (name) => `${presence(name)}`,
    width: "25%",
  },
  {
    title: "Number Plate",
    dataIndex: "entity",
    align: "center",
    render: (_, row) => `${presence(row.vehicle.number_plate)}`,
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    align: "center",
    render: (created_at) => `${formatTime(created_at)}`,
  },
  {
    title: "Last Visited",
    dataIndex: "entity.latest_entry_at",
    align: "center",
    render: (_, row) => `${timeDiff(moment(), row.vehicle.latest_entry_at)}`,
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    align: "center",
    render: (remarks) => `${presence(remarks)}`,
    width: "25%",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    render: (status) => <StatusComp value={titleize(status)} />,
  },
];
