import React from "react";
import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";
import RadioButton from "components/inputs/radioButton";
import AdornmentTextInput from "components/inputs/startAdornmentInput";

const Form = ({ data, handleChange, handleChangeNric }) => {
  const { name, department, status, phone, country_code, fin_number } = data;
  const statusProps = {
    labelText: "Status",
    value: status,
    data: ["active", "inactive"],
    onChange: handleChange,
    name: "status",
    color: true,
  };
  return (
    <Row style={{ minWidth: 500 }}>
      <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          labelText="Name"
          name="name"
          value={name}
          onChange={handleChange}
        />
      </Col>
      <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
        <AdornmentTextInput
          countryCode={`+${country_code}  - `}
          labelText="Phone"
          name="phone"
          value={phone}
          onChange={handleChange}
        />
      </Col>
      <Col xs={24} sm={12} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          labelText="NRIC/FIN (Last 4 digits)"
          name="fin_number"
          required
          value={fin_number}
          onChange={(e) => handleChangeNric(e.target.value)}
          inputProps={{
            maxLength: 9,
          }}
        />
      </Col>

      <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          labelText="Department"
          name="department"
          value={department}
          onChange={handleChange}
        />
      </Col>
      <Col xs={24} sm={12} style={{ padding: "5px 12px", width: "50%" }}>
        <RadioButton {...statusProps} />
      </Col>
    </Row>
  );
};

export default Form;
