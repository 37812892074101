import React, { Component } from "react";
import AppBar from "components/AppBar";
import RowspanTable from "components/paginationTables/rowspanTable";
import { fetchEmployeesData } from "api/employees";
import { columns } from "data/employees";
import CRUDResident from "components/form/employees/CRUD";
import { Row } from "antd";
import { createAccess, updateAccess, debounce } from "helpers";
import { handleResponse } from "components/notifications/handleResponse";

class Employees extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      formOpen: false,
      rowData: undefined,
      skeleton: true,
    };
  }
  initialRequestParams = () => {
    return {
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount() {
    this.fetchTableData();
  }

  flushTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  componentWillUnmount = () => {
    this.flushTimer();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  fetchTableData = () => {
    fetchEmployeesData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.setState({ ...this.state });
    this.debounceSearch();
  };

  handleSubmitSuccess = () => {
    this.onCancel();
    setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  onCancel = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  editEmployee = (row) => {
    this.setState({ formOpen: true, rowData: row });
  };

  employeeHistory = (row) => {
    if (row.phone) {
      this.props.history.push({
        pathname: `/employees/${row.phone}`,
        state: { row },
      });
    }
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.status = filters.status;
    this.fetchTableData();
  };

  handlePaginationChange = (page, pageSize) => {
    this.requestParams.page = page;
    this.requestParams.perPage = pageSize;
    this.searchResidents();
  };

  createAction = () => {
    return createAccess("employees");
  };

  updateAction = () => {
    return updateAccess("employees");
  };

  render() {
    const { data, formOpen, rowData, skeleton } = this.state;
    const {
      onChange,
      onCancel,
      handleSubmitSuccess,
      handlePaginationChange,
    } = this;

    const appBarProps = {
      search: this.requestParams.search,
      // searchOpen: true,
      onChange,
    };
    const tableProps = {
      tabColor: "warning",
      title: "Other Staffs",
      subTitle: "Registered staffs from other buildings",
      columns: columns(
        this.editEmployee,
        this.employeeHistory,
        this.updateAction,
        {
          data: data,
          pagination: this.pagination(),
        }
      ),
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick: () => {},
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },

      dataValue: "ADD Resident",
      skeleton,

      handlePaginationChange,
      createAction: this.createAction(),
    };

    const CRUDEmployeeProps = {
      open: formOpen,
      tabColor: "warning",
      titleText: "Employees",
      data: rowData,
      handleSubmitSuccess,
      onCancel,
      // showDelete: true,
      dateFilter: true,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <RowspanTable {...tableProps} />
        </Row>
        <CRUDResident {...CRUDEmployeeProps} />
      </React.Fragment>
    );
  }
}
export default Employees;
