import React from "react";
import AlertsScreen from "../AlertsScreen";
import { headers, getCookie } from "helpers";
import { apiHost } from "config";
import { handleResponse } from "components/notifications/handleResponse";

const fetchMenus = async (menus = []) => {
  await fetch(`${apiHost}/api/v1/presentations`, {
    headers: headers(),
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      }
      const errorMessage = [response.status, await response.json()];
      throw errorMessage;
    })
    .then(({ data }) => {
      window.localStorage.setItem("presentation", JSON.stringify(data));
      if (getCookie("presentationRoutes") === "loaded") {
        if (JSON.stringify(menus) !== JSON.stringify(data)) {
          window.location.reload();
        }
      }
      menus = data;
    })
    .catch((error) => {
      handleResponse(error);
    });
  return menus;
};

export const menu = async (cached = false) => {
  let cachedData, data;
  const presentationData = window.localStorage.getItem("presentation");
  if (presentationData) {
    try {
      cachedData = JSON.parse(presentationData);
    } catch (err) {
      console.log(err);
    }
  }

  if (cached && cachedData?.length) {
    data = cachedData;
    fetchMenus(cachedData);
  } else {
    data = await fetchMenus(cachedData);
  }
  let menus = [];
  for (let i = 0; i < data.length; i++) {
    let val = data[i];
    let alertUrl = data[i].alert_types
      .map((value) => `&type[]=${value}`)
      .join("");
    let childProps = {
      title: data[i].title,
      subTitle: data[i].subtitle,
      alertUrl: alertUrl,
    };
    menus.push({
      ...val,
      match_url: data[i].url,
      url: () => data[i].url,
      icon: data[i].icon_url,
      render: (props) => <AlertsScreen {...props} childProps={childProps} />,
    });
  }
  return menus;
};
