import React, { Component } from "react";
import { Col, Row } from "antd";
import { apiHost } from "config";
import ParkingMonitor from "./sections/AboutAlert";
import Header from "./sections/Header";
import PaginationTable from "./sections/Table/PaginationTable";
import { handleResponse } from "components/notifications/handleResponse";
import columns from "./data/column";
import { headers } from "helpers";

class AlertsScreen extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      data: [],
      index: 0,
    };
  }
  componentDidMount() {
    document.getElementsByClassName(
      "content-inside"
    )[0].style.paddingBottom = 0;
    var footer = document.getElementById("footer");
    if (footer) {
      footer.remove();
    }
    this.alertsTable();
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  };

  alertsTable = () => {
    fetch(`${apiHost}/api/v1/alerts?${this.props.childProps.alertUrl}`, {
      headers: headers(),
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        const errorMessage = [response.status, await response.json()];
        throw errorMessage;
      })
      .then(({ data }) => {
        this.setState({ data });
      })
      .catch(handleResponse);
  };

  handleClick = (_, i) => {
    this.setState({ index: i });
  };

  goBack = () => {
    this.props.history.push("/alerts/overview");
  };

  render() {
    const { data, index } = this.state;
    const { handleClick, goBack } = this;
    const { childProps } = this.props;
    let totalRaisedAlerts =
      data && data.filter((obj) => obj.status === "Raised").length;
    const timeLine = {
      data: data[index],
      totalRaisedAlerts,
      goBack,
      refreshData: this.alertsTable,
    };

    const tableProps = {
      tabColor: "primary",
      title: childProps.title,
      subTitle: childProps.subTitle,
      columns: columns,
      data: data,
      onRowClick: handleClick,
    };

    return (
      <React.Fragment>
        <Header goBack={goBack} />
        <Row>
          <Col sm={15} style={{ paddingTop: 30, paddingLeft: 30 }}>
            <ParkingMonitor {...timeLine} />
          </Col>
          <Col sm={9} style={{ paddingRight: 30 }}>
            <PaginationTable {...tableProps} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default AlertsScreen;
