import moment from "moment";
import React from "react";

export const columns = () => {
  return [
    {
      title: "Plan Name",
      dataIndex: "plan",
      render: (row) => {
        return row;
      },
      align: "center",
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      render: (start_time) => {
        let dateAndTime = start_time?.split("_");
        return `${moment(dateAndTime[0]).format("DD,MMM,YY")} - ${dateAndTime[1]
          }`;
      },
      align: "center",
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      render: (end_time) => {
        let dateAndTime = end_time?.split("_");
        return `${moment(dateAndTime[0]).format("DD,MMM,YY")} - ${dateAndTime[1]
          }`;
      },
      align: "center",
    },
    {
      title: "Manage",
      render: (row) => {
        return (
          <React.Fragment>
            <a
              href={row?.report_url}
              target={"_blank"}
              rel={"noopener noreferrer"}
              style={{
                margin: "0px 10px 5px 10px",
                padding: "8px",
                backgroundColor: "lightgrey",
                borderColor: "#d9d9d9",
                border: "1px solid transperant",
                borderRadius: "4px",
                color: "#000",
              }}
              disabled={row?.report_url ? false : true}
            >
              Download
            </a>
          </React.Fragment>
        );
      },
      align: "center",
    }
  ];
};
