import React, { Component } from "react";
import AppBar from "components/AppBar";
import { Row } from "antd";
import { columns } from "data/completed";
import PaginationTable from "components/paginationTables/withAddEdit";
import Joystick from "assets/img/gamepad.svg";
import Pause from "assets/img/pause.svg";
import Stop from "assets/img/stop.svg";
import { webSocketHost } from "config";

class Completed extends Component {
  constructor(props) {
    super(props);
    this.rosSocket = new WebSocket(webSocketHost);
    this.state = {
      skeleton: true,
      data: [],
    };
  }

  fetchTableData = () => {
    this.rosSocket.onopen = () => {
      console.log("socket open")
      this.rosSocket.send(
        `{"channel":"non-ros","msg_type":"request","type":"service","service":"database","data":{"fetch":"completed"}}`
      );
      this.rosSocket.onmessage = (e) => {
        console.log("onmessage")
        let parsedData = JSON.parse(e.data);
        this.setState({
          data: parsedData?.records?.schedules,
          skeleton: false,
        });
      };
    };
  };

  componentDidMount() {
    this.fetchTableData();
  }

  render() {
    const { skeleton, data } = this.state;
    const tableProps = {
      tabColor: "success",
      title: "Completed",
      subTitle: "Completed schedules are listed here",
      columns: columns({ data: data }, this.downloadHandler),
      data: data,
      onRowClick: () => { },
      skeleton,
    };

    return (
      <React.Fragment>
        <AppBar></AppBar>
        <Row>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <span style={{ marginRight: "20px", textAlign: "center" }}>
              <img src={Joystick} />
              <p>Joystick</p>
            </span>
            <span style={{ marginRight: "20px", textAlign: "center" }}>
              <img src={Pause} />
              <p>Pause</p>
            </span>
            <span style={{ marginRight: "20px", textAlign: "center" }}>
              <img src={Stop} />
              <p>Stop</p>
            </span>
          </div>
          <PaginationTable {...tableProps} />
        </Row>
      </React.Fragment>
    );
  }
}
export default Completed;
