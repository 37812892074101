import React from "react";
import { Col, Row } from "antd";
import VehicleTimeLineDetail from "./Timeline";
import { apiHost } from "config";
import { IoIosArrowRoundBack } from "react-icons/io";
import { makeStyles } from "@material-ui/core/styles";
import defaultImage from "assets/img/default_image.jpg";

const AboutAlert = ({ data, totalRaisedAlerts, goBack, refreshData }) => {
  const countBox = (backgroundColor, val) => (
    <div
      style={{
        width: 90,
        height: 50,
        borderRadius: 6,
        backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p
        style={{
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "2em",
          color: "#DFE1F3",
          fontFamily: "Calibri",
          margin: 0,
        }}
      >
        {val}
      </p>
    </div>
  );
  const nodata = (
    <h1
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
      }}
    >
      No Data to display
    </h1>
  );
  const useStyles = makeStyles({
    btn: {
      border: "2px solid black",
      background: "white",
      outline: "none",
      display: "flex",
      borderRadius: "50%",
      padding: "2px 7px",
      cursor: "pointer",
      height: "40px",
      "&:hover": {
        display: "flex",
        borderRadius: "50%",
        "&:active": {
          outline: "none",
          border: "3px solid black",
        },
      },
    },
  });
  const classes = useStyles();
  return (
    <React.Fragment>
      <span style={{ width: "50px" }} onClick={goBack}>
        <button className={classes.btn}>
          <IoIosArrowRoundBack style={{ color: "black", fontSize: "25px" }} />
        </button>
      </span>
      <div
        style={{
          display: "flex",
          width: "80%",
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "-30px",
        }}
      >
        <span style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              fontFamily: "Corbel",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "1.3em",
              color: "#000",
              marginRight: "20px",
            }}
          >
            Open Alerts
          </span>
          <span>{countBox("#E50027", totalRaisedAlerts)}</span>
        </span>
      </div>

      {data ? (
        <Row
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {/* video */}
          {data.video_path && (
            <Col sm={24}>
              <div
                style={{
                  height: 450,
                  width: "65%",
                  margin: "auto",
                  paddingBottom: 10,
                }}
              >
                <video controls key={data.id}>
                  <source src={data.video_path} type="video/mp4" />
                </video>
              </div>
            </Col>
          )}
          {/* video */}
          {data.image_path && (
            <Col sm={24}>
              <div style={{ height: 450, width: "65%", margin: "auto" }}>
                <img
                  src={
                    (data.s3_image_path
                      ? data.s3_image_path
                      : `${apiHost}/${data.image_path}`) || defaultImage
                  }
                  style={{ width: "100%", height: "100%" }}
                  alt="webcam snap"
                />
              </div>
            </Col>
          )}
          <Col sm={24} style={{ paddingTop: 20 }}>
            <VehicleTimeLineDetail data={data} refreshData={refreshData} />
          </Col>
        </Row>
      ) : (
        nodata
      )}
    </React.Fragment>
  );
};

export default AboutAlert;
