import * as React from "react";
import moment from "moment";
import HeatMap from "components/charts/heatMap";
import { AppBarContext } from "context/ContextProvider";
import { trafficData } from "api/dashboard";
import { handleResponse } from "components/notifications/handleResponse";

class HeatMapVehicles extends React.Component {
  constructor(props) {
    super(props);
    this.camera_type = ["resident_entry_cam", "visitor_entry_cam"];
    this.camera_ids = [];
    this.state = {
      graph_data: [],
      menuOpen: false,
      maxValue: 0,
      duration: 8 * 24,
    };
  }

  componentDidMount() {
    this.heatMapData();
  }

  heatMapData = async () => {
    await trafficData(this.state.duration)
      .then(async ({ data }) => {
        if (data && data?.length !== 0) {
          this.setState({ graph_data: await this.convertHeatMapData(data) });
        }
      })
      .catch((error) => {
        handleResponse(error);
        console.log("heatmap ##", error);
      });
  };

  convertHeatMapData = async (data) => {
    let object = {};
    let count = 0;
    let max = 0;
    let data1 = data || [];
    this.camera_ids = this.props.cameras
      .filter((camera) => this.camera_type.includes(camera.camera_type))
      .map((camera) => camera.id);
    data1 &&
      data1.length > 0 &&
      data1.reverse().forEach((obj) => {
        this.camera_ids.forEach((camera_id) => (count += obj[camera_id]));
        if (moment(obj.datetime).minute() === 0) {
          let str = moment(obj.datetime).format("YYYY-MM-DD");
          object[str] = object[str] || {};
          object[str][moment(obj.datetime).format("H")] = count;
          if (count > max) {
            max = count;
          }
          count = 0;
        }
      });
    let new_array = [];
    let label = [];
    for (let i = 7; i >= 1; i--) {
      object[
        moment(new Date())
          .subtract(i, "days")
          .format("YYYY-MM-DD")
      ] &&
        new_array.push(
          Object.values(
            object[
              moment(new Date())
                .subtract(i, "days")
                .format("YYYY-MM-DD")
            ]
          )
        );
      object[
        moment(new Date())
          .subtract(i, "days")
          .format("YYYY-MM-DD")
      ] && label.push(Object.keys(object));
    }
    let ss = [];
    label[0] &&
      label[0].forEach((o) => {
        ss.push(moment(o).format("DD-MMM"));
      });
    this.setState({ maxValue: max, label: ss });
    return new_array;
  };

  render() {
    return (
      <React.Fragment>
        <HeatMap
          data={this.state.graph_data}
          title="Timing Based Traffic Pattern during Last 7 days"
          // subTitle="Traffic Pattern segmented by hours of the day"
          max={this.state.maxValue}
          label={this.state.label}
        />
        <AppBarContext.Consumer>
          {({ state: { menuOpen } }) => {
            if (menuOpen !== this.state.menuOpen) {
              setTimeout(
                function() {
                  this.heatMapData();
                }.bind(this),
                100 * 2.5
              );
              this.setState({ menuOpen });
            }
          }}
        </AppBarContext.Consumer>
      </React.Fragment>
    );
  }
}
export default HeatMapVehicles;
