import React, { Component } from "react";
import { Table } from "antd";
import Skeleton from "components/Skeleton";

class TableBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loader: false,
    };
  }

  render() {
    const {
      columns,
      data,
      pagination,
      handleTableChange,
      onRowClick,
      skeleton,
      loading,
      loader,
    } = this.props;

    return (
      <div
        style={{
          overflowX: "auto",
          width: "100%",
        }}
      >
        {skeleton ? (
          <Skeleton />
        ) : (
          <Table
            columns={columns}
            onRow={(record) => {
              return {
                onClick: () => {
                  onRowClick(record);
                },
              };
            }}
            rowKey={(record) => record.id}
            dataSource={data}
            onChange={pagination && handleTableChange}
            pagination={
              pagination
                ? {
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} results`,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "25", "50", "100"],
                    ...pagination,
                  }
                : { onChange: () => "" }
            }
            loading={loading || loader}
          />
        )}
      </div>
    );
  }
}

export default TableBody;
