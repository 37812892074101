import React from "react";
import { presence, timeDiff } from "helpers";
import { apiHost, showLnprNumberPlate } from "config";
import ColIcons from "components/ColIcons";
import tourist from "assets/img/tourist.svg";
import camera from "assets/img/casino-cctv.svg";
import alert from "assets/img/alert.svg";
import bike from "assets/img/bike.svg";
import resident from "assets/img/resident.svg";
import { DurationComp } from "data/index";
import moment from "moment";

export const columns = () => {
  return [
    {
      align: "center",
      render: (row) => <IconComp row={row} />,
    },
    {
      title: "Image",
      dataIndex: "number_plate_image",
      align: "center",
      width: "150px",
      render: (number_plate_image) =>
        number_plate_image.map((img, i) => (
          <>
            {img.startsWith("https") ? (
              <img
                key={i}
                src={img}
                alt="Number Plate"
                style={{ maxWidth: 150, marginTop: 5, display: "block" }}
              />
            ) : (
              <img
                key={i}
                src={`${apiHost}/${img}`}
                alt="Number Plate"
                style={{ maxWidth: 150, marginTop: 5, display: "block" }}
              />
            )}
          </>
        )),
    },

    {
      title: showLnprNumberPlate === true ? "Number Plate" : false,
      render: (_, row) => {
        return (
          <>
            {showLnprNumberPlate === true && (
              <p style={{ fontSize: "120%", fontWeight: 600 }}>
                {presence(row.number_plate)}
              </p>
            )}
          </>
        );
      },
      align: "center",
    },
    {
      title: "Duration",
      render: (row) => <DurationComp row={row} />,
      align: "center",
    },
    {
      title: "Vehicle Location",
      dataIndex: "detected_cams",
      align: "center",
      render: (detected_cams) =>
        detected_cams.map((detected_cam, i) => <p key={i}>{detected_cam}</p>),
    },
    {
      title: "Current Status",
      align: "center",
      render: (row) => <StatusComp data={row} />,
    },
  ];
};

const StatusComp = ({ data: { status, expected_out_time, vehicle_type } }) => {
  if (status === "Direct Out") {
    status = "Out";
  }
  if (status === "Neglected") {
    status = "Out";
  }
  if (status === "Partially Out") {
    status = "Out";
  }
  let backgroundColor = {
    In: "#2196f3",
    Out: "#009688",
    Expired: "#ff5252",
    "Direct Out": "#ffc107",
    "Expired And Out": "#9c27b0",
    Neglected: "#ff9800",
  }[status];

  const past = moment(expected_out_time) < moment();

  return (
    <>
      <span
        style={{
          color: "#fff",
          backgroundColor,
          padding: "4px 15px 3px",
          borderRadius: 50,
        }}
      >
        {status === "Direct Out"
          ? "Out"
          : status === "Neglected"
          ? "In"
          : status}
      </span>
      {expected_out_time &&
        ["In", "Expired"].includes(status) &&
        vehicle_type !== "Motor Bike" && (
          <span
            style={{ display: "flex", flexDirection: "column", marginTop: 10 }}
          >
            <span>{past ? "should have exited" : "should exit in"}</span>
            <span style={{ color: past ? "red" : "green" }}>
              {timeDiff(moment(), moment(expected_out_time))}
            </span>
          </span>
        )}
    </>
  );
};

const IconComp = ({ row }) => {
  return (
    <React.Fragment>
      {row.trackings && row.trackings.length !== 0 && (
        <div>
          <ColIcons icon_path={camera} count={row.trackings.length} />
          &nbsp;
        </div>
      )}
      {row.alerts && row.alerts.length !== 0 && (
        <div>
          <ColIcons icon_path={alert} count={row.alerts.length} />
          &nbsp;
        </div>
      )}
      {row.vehicle_type === "Motor Bike" && (
        <div>
          <ColIcons icon_path={bike} width="25px" height="25px" /> &nbsp;
        </div>
      )}
      {row.visit_linked && <ColIcons icon_path={tourist} count={1} />}
      {row.owner_type === "Resident" && (
        <ColIcons icon_path={resident} count={1} />
      )}
    </React.Fragment>
  );
};
