import React from "react";
import { Row, Col } from "antd";
import CRUDDateTimePicker from "components/CRUDDateTimePicker";

export default function Form(props) {
  const { reschedule_data, handleChange } = props;
  return (
    <React.Fragment>
      <Row spacing={3} style={{ padding: 10 }}>
        <Col xs={24} sm={12} style={{ padding: "0px 10px" }}>
          <CRUDDateTimePicker value={reschedule_data} onChange={handleChange} />
        </Col>
      </Row>
    </React.Fragment>
  );
}
