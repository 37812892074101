import React, { Component } from "react";
import { Table, Pagination } from "antd";
import Skeleton from "components/Skeleton";

class RowspanBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const {
      columns,
      data,
      pagination,
      handleTableChange,
      onRowClick,
      skeleton,
      handlePaginationChange,
    } = this.props;
    return (
      <div
        style={{
          overflowX: "auto",
          width: "100%",
        }}
      >
        {skeleton ? (
          <Skeleton />
        ) : (
          <>
            <Table
              bordered={true}
              columns={columns}
              onRow={(record) => {
                return {
                  onClick: () => {
                    onRowClick(record);
                  },
                };
              }}
              rowKey={(record, index) =>
                record.unit_number + "%" + index * pagination.current
              }
              dataSource={data}
              onChange={handleTableChange}
              pagination={false}
            />
            <Pagination
              style={{
                padding: 10,
                display: "flex",
                justifyContent: "flex-end",
              }}
              onChange={handlePaginationChange}
              showSizeChanger
              onShowSizeChange={(current, size) =>
                handlePaginationChange(1, size)
              }
              {...pagination}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} results`
              }
              pageSizeOptions={["10", "25", "50", "100"]}
            />
          </>
        )}
      </div>
    );
  }
}

export default RowspanBody;
